import {useEffect, useState} from "react";
import MDEditor from '@uiw/react-md-editor';
import {supabase} from "../utils/supabase";
import {Button} from "@mantine/core";
import toast from "react-hot-toast";

export default function AdminPatchNotes(){
    const [value, setValue] = useState("");

    async function GetPNotes(){
        const {data} = await supabase
            .from('patchnotes')
            .select()
            .eq("id", 1);
        if (data && data.length > 0) {
            setValue(data[0].md);
        } else {
            setValue("");
        }
    }

    async function Save(){
       await supabase.from('patchnotes')
            .update({md: value})
            .eq('id', 1)
            .select().then(result => {
            if(result.error){
                toast.error("Failed to save patch notes: " + result.error, {
                    duration: 4000,
                    position: 'bottom-right',
                });
            } else {
                toast.success("Patch notes succesfully saved. ", {
                    duration: 4000,
                    position: 'bottom-right',
                });
            }
        })
    }


    useEffect(() => {
        if(value === "") GetPNotes();
    }, [value]);

    return (
        <div className={"flex flex-col w-full h-full overflow-hidden"}>
            <div className={"flex flex-row w-full h-full overflow-hidden"}>
                <MDEditor
                    value={value}
                    onChange={setValue}
                    height="100%"
                />
            </div>
            <div className={"flex flex-row w-full h-10 overflow-hidden justify-center items-center"}>
                <Button onClick={() => Save()}>Save</Button>
            </div>
        </div>
    )
}