import React, {useCallback, useEffect, useMemo, useState} from "react";
import {DeckBuilder} from "./deckbuilder/deckbuilder";
import AuthPanel, {ResetPasswordPanel} from "./components/authpanel";
import TOSModal from "./components/tos";
import PrivacyModal from "./components/privacy";
import {Link, Navigate, Route, Routes, useLocation} from "react-router-dom";
import Home from "./pages/home";
import RuleBook from "./pages/rulebook";
import TreasuryModal from "./pages/treasury";
import AccountPage from "./pages/accountpage";
import Tooltip from "@mui/material/Tooltip";
import HomeIcon from "@mui/icons-material/Home";
import ChatIcon from '@mui/icons-material/Chat';
import {CollectionsBookmark} from "@mui/icons-material";
import {bordercolor} from "./theme";
import {SocialPanel} from "./social/socialpanel";
import AdminPanel from "./admin/adminpanel";
import Statistics from "./statistics/statistics";
import CardSheet from "./deckbuilder/cardsheet";
import {Badge} from "@mui/material";
import {useSocial} from "./hooks/useSocial";
import PublicDecks from "./deckbuilder/publicDecks";
import InGame from "./pages/ingame";
import Profile from "./pages/profile";
import Privacy from "./pages/privacy";
import TOS from "./pages/tos";
import ChatWindow from "./social/chatwindow";
import { useAtom } from 'jotai';
import {
    sessionAtom,
    userIdAtom,
    isAdminAtom,
    loadingInitialAtom,
    isInitialLoadAtom,
    hideMenuAtom,
    discordAtom,
    profileAtom, openPageAtom, goldAtom
} from './atoms/appAtoms';
import {gethDateTime, supabase} from "./utils/supabase";
import {useAtomValue} from "jotai/index";
import {deckWindowAtom, myDecksAtom} from "./atoms/deckBuilderAtoms";

export const dblink = "https://services.nanoserver.xyz";

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

export default function App() {
    const myDecks = useAtomValue(myDecksAtom);
    const [spanelOpen, setSPanelOpen] = useState(false);
    const [tosOpen, setTosOpen] = useState(false);
    const [privOpen, setPrivOpen] = useState(false);
    const [hoverId, setHoverID] = useState("");
    const [hoverType, setHoverType] = useState("");
    const [treasuryOpen, setTreasuryOpen] = useState(false);
    let query = useQuery();
    const [session, setSession] = useAtom(sessionAtom);
    const isAdmin = useAtomValue(isAdminAtom);
    const [loadingInitial, setLoadingInitial] = useAtom(loadingInitialAtom);
    const [isInitialLoad, setIsInitialLoad] = useAtom(isInitialLoadAtom);
    const [hideMenu, setHideMenu] = useAtom(hideMenuAtom);
    const [profile, setProfile] = useAtom(profileAtom);
    let route = useLocation();

    const createProfile = async (_id, _username, _email) => {
        if (_id === '') {
            setProfile(null);
            if (loadingInitial) setLoadingInitial(false);
            return;
        }

        const { error } = await supabase.from('profiles').insert({
            username: _username,
            steamid: 0,
            gold: 150,
            lastlogin: gethDateTime(),
            banned: false,
            level: 1,
            goldlog: [{ amount: 150, reason: 'Registration', date: gethDateTime() }],
            lastdaily: '1',
            online: false
        });

        if (error) {
            setProfile(null);
            if (loadingInitial) setLoadingInitial(false);
        } else {
            await getProfile(_id, _username, _email);
        }
    };

    const getProfile = async (_id, _username, _email) => {
        if (_id === '') {
            setProfile(null);
            if (loadingInitial) setLoadingInitial(false);
            return;
        }

        try {
            const { data, error } = await supabase
                .from('profiles')
                .select()
                .eq('user_id', _id);

            if (error) throw error;

            if (data.length > 0) {
                setProfile(data[0]);
                if (loadingInitial) setLoadingInitial(false);
            } else {
                await createProfile(_id, _username, _email);
            }
        } catch (error) {
            console.error('Error getting profile:', error);
            setProfile(null);
            if (loadingInitial) setLoadingInitial(false);
        }
    };

    useEffect(() => {
        if(isInitialLoad){
            supabase.auth.getSession().then(({ data: { session } }) => {
                setSession(session);
                setIsInitialLoad(false);
            });
            const {
                data: { subscription },
            } = supabase.auth.onAuthStateChange((_event, session) => {
                setSession(session)
            })
            setIsInitialLoad(false);

            return () => subscription.unsubscribe()
        }

        if (session) {
            const { user } = session;
            const username = user.user_metadata.user_name || user.user_metadata.full_name;
            getProfile(user.id, username, user.user_metadata.email);
        } else {
            setProfile(null);
            setLoadingInitial(false);
            setSPanelOpen(false);
            setTreasuryOpen(false);
        }
    }, [isInitialLoad, session]);

    if (route.pathname.split("/")[1] === "ingame") {
        return (
            <div onContextMenuCapture={(e) => {
                e.preventDefault();
            }} className={"flex flex-col font-noto overscroll-none h-screen w-screen overflow-hidden text-slate-200"}>
                <img className={"absolute flex-row h-screen w-screen z-0 "}
                     src={"gradient_bg01.png"} alt={"bg"}/>
                <div className={"absolute h-full w-full flex flex-col overflow-hidden"}>
                    <Routes>
                        {!session ? <Route path='/ingame/db' element={<InGame reqtoken={query.get("reqtoken")}
                                                                           acctoken={query.get("acctoken")}/>}/> :
                            <Route path='/ingame/db' element={<DeckBuilder cardsOnly={false} setHoverType={setHoverType}
                                                                        setHoverID={setHoverID} hoverId={hoverId}/>}/>
                        }
                        {!session ? <Route path='/ingame/stats' element={<InGame reqtoken={query.get("reqtoken")}
                                                                              acctoken={query.get("acctoken")}/>}/> :
                            <Route path='/ingame/stats' element={<Statistics cardsOnly={false} setHoverType={setHoverType}
                                                                           setHoverID={setHoverID} hoverId={hoverId}/>}/>
                        }
                    </Routes>
                </div>
                {hoverId !== "" && <div
                    className={"absolute flex flex-col min-h-40 h-auto w-[400px] p-3 overflow-hidden self-center justify-self-center bg-[#0a0f13] rounded-2xl border " + bordercolor + (hoverType === "decks" ? " right-[10px] top-[9%] " : " right-[405px] top-[30%] ")}>
                    <img className={"h-auto w-full rounded-2xl"} alt={"preview" + hoverId}
                         src={"/cards/" + hoverId + ".png"}/>
                </div>}
                <div className={"hidden bg-red-600 text-black bg-blue-500 bg-amber-500 bg-cyan-500 bg-slate-900 bg-slate-700 bg-fuchsia-700 bg-rose-700 bg-purple-700 bg-gray-700 "}></div>
            </div>
        )
    } else if (loadingInitial) return (<div>

    </div>)
    else {
        if (route.pathname.split("/")[1] === "cardbrowser" || route.pathname.split("/")[1] === "cb") {
            return (
                <div onContextMenuCapture={(e) => {
                    e.preventDefault();
                }}
                     className={"flex flex-col font-noto overscroll-none h-screen w-screen overflow-hidden text-slate-200"}>
                    <img className={"absolute flex-row h-screen w-screen z-0 "}
                         src={"gradient_bg01.png"} alt={"bg"}/>
                    <div className={"absolute h-full w-full flex flex-col overflow-hidden"}>
                        <Routes>
                            <Route path='/cb'
                                   element={<DeckBuilder cardsOnly={true} setHoverType={setHoverType}
                                                         setHoverID={setHoverID} hoverId={hoverId}/>}/>
                            <Route path='/cardbrowser'
                                   element={<DeckBuilder cardsOnly={true} setHoverType={setHoverType}
                                                         setHoverID={setHoverID} hoverId={hoverId}/>}/>
                        </Routes>
                    </div>
                </div>
            )
        } else {
            if(isInitialLoad){
                return (<></>);
            } else
            return (
                <div onContextMenuCapture={(e) => {
                    e.preventDefault();
                }}
                     className={"flex flex-col font-noto overscroll-none h-screen w-screen overflow-hidden text-slate-200"}>
                    {!session && <div className={"absolute h-screen w-full"}>
                        <img alt={"bg"} className={"opacity-90 h-full w-full"}
                             src={"./Sungrace-Gardens-e1706124188182.png"}/>
                    </div>}
                    <img className={"absolute flex-row h-screen w-screen z-0 " + (!hideMenu ? " flex" : " hidden")}
                         src={"gradient_bg01.png"} alt={"bg"}/>
                    <TopMenu treasuryOpen={treasuryOpen} setTreasuryOpen={setTreasuryOpen}
                             setSPanelOpen={setSPanelOpen} spanelOpen={spanelOpen}/>
                    <div className={"relative h-full w-full flex flex-col overflow-hidden "}>
                        <Routes>
                            <Route path='/' element={session ? <Home friendid={query.get("friendid")}/> : <AuthPanel/>}/>
                            <Route path='/resetpass' element={<ResetPasswordPanel />}/>
                            <Route path='/privacy' element={<Privacy/>}/>
                            <Route path='/tos' element={<TOS/>}/>
                            <Route path='/rules' element={<RuleBook/>}/>
                            <Route path='/profile' element={<Profile playerid={query.get("playerid")}/>}/>
                            <Route path='/chat' element={session ? <ChatWindow /> : <Navigate to="/" />} />
                            <Route path='/cardbrowser' element={<DeckBuilder cardsOnly={true} setHoverType={setHoverType} setHoverID={setHoverID} hoverId={hoverId}/>}/>
                            <Route path='/decks' element={session ? <PublicDecks setHoverType={setHoverType}  fullpage={true} setHoverID={setHoverID} hoverId={hoverId} hideMenu={hideMenu}/> : <Navigate to="/" />} />
                            <Route path='/d' element={session ? <Navigate to="/decks" /> : <Navigate to="/" />} />
                            <Route path='/builder' element={session ? <DeckBuilder cardsOnly={false} setHoverType={setHoverType} setHoverID={setHoverID} hoverId={hoverId}/> : <Navigate to="/" />} />
                            <Route path='/deckbuilder' element={session ? <Navigate to="/builder" /> : <Navigate to="/" />} />
                            <Route path='/db' element={session ? <Navigate to="/builder" /> : <Navigate to="/" />} />
                            <Route path='/account' element={session ? <AccountPage/> : <Navigate to="/" />} />
                            <Route path='/a' element={session ? <AccountPage/> : <Navigate to="/" />} />
                            <Route path='/statistics' element={session ? <Statistics/> : <Navigate to="/" />} />
                            <Route path='/s' element={session ? <Navigate to="/statistics" /> : <Navigate to="/" />} />
                            <Route path='/cardsheet' element={session ? <CardSheet/> : <Navigate to="/" />} />
                            <Route path='/cs' element={session ? <Navigate to="/cardsheet" /> : <Navigate to="/" />} />
                            <Route path='/admin' element={session && isAdmin ? <AdminPanel/> : <Navigate to="/" />} />
                            <Route path='/a' element={session && isAdmin ? <Navigate to="/admin" />  : <Navigate to="/" />} />
                            <Route path="*" element={<Navigate to="/"/>}/>
                        </Routes>
                    </div>
                    {/*openPage === "main" && !inMatch && <NewsCarousel setOpenPage={setOpenPage} />*/}
                    {/* inMatch && <MatchChat matchID={matchID} inMatch={inMatch} /> */}
                    <SocialPanel panelOpen={spanelOpen} setPanelOpen={setSPanelOpen}/>
                    {treasuryOpen && <TreasuryModal treasuryOpen={treasuryOpen} setTreasuryOpen={setTreasuryOpen}/>}
                    <TOSModal setTosOpen={setTosOpen} tosOpen={tosOpen}/>
                    <PrivacyModal privOpen={privOpen} setPrivOpen={setPrivOpen}/>
                    {hoverId !== "" && <div
                        className={"absolute flex flex-col min-h-40 h-auto w-[400px] z-50 p-3 overflow-hidden self-center justify-self-center bg-[#0a0f13] rounded-2xl border " + bordercolor + (hoverType === "decks" ? " right-[10px] top-[9%] " : " right-[405px] top-[30%] ")}>
                        <img className={"h-auto w-full rounded-2xl"} alt={"preview" + hoverId}
                             src={"/cards/" + hoverId + ".png"}/>
                    </div>}
                </div>
            )

        }
    }
}

const sdeckstyle = "flex flex-col h-full w-[500px] bg-header items-center justify-start rounded-2xl px-4";
const sdeckbutton = "flex h-14 w-full justify-center items-center bg-secondary";

const selbuttonCol = "bg-[#4CB190C3] border border-[#3b6f5e26]"
const buttonCol = "bg-[#3b6f5e26] border border-[#4CB1904A]"
const mainbutton = "flex flex-row text-center !text-[#51b78c] h-12 justify-center items-center w-[120px] hover:opacity-75  "

const col = "rgb(25,48,37)";

function TopMenu(props) {
    const {setSPanelOpen, spanelOpen, setTreasuryOpen, treasuryOpen} = props;
    const {RequestsCounter, ChannelCounter} = useSocial();
    const [deckWindow, setDeckWindow] = useAtom(deckWindowAtom);
    const profile = useAtomValue(profileAtom);
    const session = useAtomValue(sessionAtom);
    let route = useLocation();

    const openPage = () => {
        if (route.pathname === "/") {
            return "/";
        } else {
            return route.pathname.split("/")[1]
        }
    }

    const UnreadCount = () => {
        let am = ChannelCounter();
        am -= localStorage.getItem("readmessages") ? parseInt(localStorage.getItem("readmessages")) : 0;
        am += RequestsCounter();
        return am;
    }

    return (
        <div
            className={`relative flex flex-row items-center bg-[#01130FF7] overflow-hidden w-full h-[80px] border-b justify-start md:justify-between ` +
                bordercolor}>
            <Link to={"/"} target={"_self"}>
                <img src="/logo192.png"
                     alt="Manacaster"
                     className="my-auto hidden lg:flex ml-2 h-12 w-auto hover:opacity-60"
                />
            </Link>
            <div className={"flex w-full h-full items-center justify-end flex-row select-none"}>
                <div className="hidden lg:flex w-auto h-full items-end justify-center gap-3 flex-row lg:pr-4 lg:pb-4">
                    <Tooltip title="Open Main Screen" placement="bottom">
                        <Link to={"/"} target={"_self"}>
                            <div
                                className={"flex flex-row text-center !text-[#51b78c] h-12 justify-center items-center w-12 hover:opacity-75  " +
                                    (openPage() === "/" ? selbuttonCol : buttonCol)}>
                                <HomeIcon/>
                            </div>
                        </Link>
                    </Tooltip>
                    {session && <Tooltip title="Open the public decks list." placement="bottom">
                        <Link to={openPage() === "builder" && deckWindow ? "/" : "/builder"} onClick={() => {
                            setDeckWindow(true);
                        }}>
                            <div className={mainbutton +
                                (openPage() === "builder" && deckWindow ? selbuttonCol : buttonCol)}>
                                Decks
                            </div>
                        </Link>
                    </Tooltip>}
                    {session && <Tooltip title="Open the Deck Builder." placement="bottom">
                        <Link to={openPage() === "builder" && !deckWindow ? "/" : "/builder"} target={"_self"}
                              onClick={() => {
                                  setDeckWindow(false);
                              }}>
                            <div className={mainbutton +
                                (openPage() === "builder" && !deckWindow ? selbuttonCol : buttonCol)}>
                                DeckBuilder
                            </div>
                        </Link>
                    </Tooltip>}
                    {session && <Tooltip title="Open your game statistics." placement="bottom">
                        <Link to={"/statistics"}>
                            <div className={mainbutton +
                                (openPage() === "statistics" ? selbuttonCol : buttonCol)}>
                                Statistics
                            </div>
                        </Link>
                    </Tooltip>}
                    {/*<Tooltip title="Open the Rulebook" placement="bottom">
                        <Link to={openPage() === "rules" ? "/" : "/rules"} target={(openPage() === "game" && playConfirm) ? "_blank" : "_self"}>
                            <div className={mainbutton +
                                (openPage() === "rules" ? selbuttonCol : buttonCol)} >
                                Rulebook
                            </div>
                        </Link>
                    </Tooltip>*/}
                </div>
                {session && profile &&
                    <div className={"flex w-[300px] h-full items-center justify-center flex-row gap-1"}>
                        <div className={"flex flex-row hover:bg-opacity-60 h-14 cursor-pointer w-full gap-2 justify-around bg-opacity-40 items-center rounded-lg border border-[#4CB1904A] "
                                + (spanelOpen ? "bg-green-300" : "bg-black")}
                            onClick={() => {
                                setSPanelOpen(!spanelOpen);
                            }}>
                            <p>{profile && profile.username}</p>
                            <Badge badgeContent={UnreadCount()} color="success"
                                   anchorOrigin={{vertical: 'top', horizontal: "left"}}>
                                <ChatIcon fontSize={"medium"}/>
                            </Badge>
                        </div>
                        <div className={"flex flex-row w-full lg:w-auto h-full justify-end lg:justify-around gap-1 lg:gap-2 items-center pr-2"}>
                            <div className={"flex lg:hidden flex-row w-auto h-14 gap-1 justify-center items-center"}>
                                <Tooltip title="Open Main Screen" placement="bottom">
                                    <Link to={"/"} target={"_self"}>
                                        <div className={"flex flex-row text-center !text-[#51b78c] h-12 justify-center items-center w-12 hover:opacity-75  " +
                                                (openPage() === "/" ? selbuttonCol : buttonCol)}>
                                            <HomeIcon/>
                                        </div>
                                    </Link>
                                </Tooltip>
                                <Tooltip title="Open Deck Builder" placement="bottom">
                                    <Link to={openPage() === "decks" ? "/" : "/decks"}
                                          target={"_self"}>
                                        <div
                                            className={"flex flex-row text-center !text-[#51b78c] h-12 justify-center items-center w-12 hover:opacity-75  " +
                                                (openPage() === "decks" ? selbuttonCol : buttonCol)}>
                                            <CollectionsBookmark/>
                                        </div>
                                    </Link>
                                </Tooltip>
                            </div>
                        </div>
                </div>}
            </div>
        </div>
    )
}
