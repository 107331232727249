import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import {ActionIcon, Badge, Button, Card, Paper, ScrollArea} from "@mantine/core";
import RefreshIcon from "@mui/icons-material/Refresh";

export default function MatchLogList(props) {
    const {activeReport, activeSort, setActiveSort, setActiveReport, SortedReports, activeType, setActiveType, admin, GetReports} = props;

    return (
        <div className="flex flex-col h-full overflow-hidden justify-start items-center w-[20%] space-y-2 p-2">
            <Card withBorder p={"sm"} md={"sm"} className={"flex flex-col w-full h-auto justify-center items-center overflow-hidden "}>
                <div className={"flex flex-row h-10 w-[80%]"}>
                    <Select
                        className={"bg-[#244939] border border-[#51b78c] text-white h-8 w-1/2"}
                        id="Identity"
                        placeholder={activeSort}
                        value={activeSort}
                        onChange={(e) => {
                            setActiveSort(e.target.value)
                        }}>
                        <MenuItem value={"descTime"}>Time Descending</MenuItem>
                        <MenuItem value={"ascTime"}>Time Ascending</MenuItem>
                    </Select>
                    {!admin? <Select
                        className={"bg-[#244939] border border-[#51b78c] text-white h-8 w-1/2 "}
                        id="Identity"
                        placeholder={activeType}
                        value={activeType}
                        onChange={(e) => {
                            setActiveType(e.target.value)
                        }}
                    >
                        <MenuItem value={"all"}>All</MenuItem>
                        <MenuItem value={"wins"}>Wins</MenuItem>
                        <MenuItem value={"losses"}>Losses</MenuItem>
                        <MenuItem value={"casual"}>Casual</MenuItem>
                        <MenuItem value={"draftpvp"}>DraftPvP</MenuItem>
                    </Select> :  <Select
                        className={"bg-[#244939] border border-[#51b78c] text-white h-8 w-1/2 "}
                        id="Identity"
                        placeholder={activeType}
                        value={activeType}
                        onChange={(e) => {
                            setActiveType(e.target.value)
                        }}
                    >
                        <MenuItem value={"all"}>All</MenuItem>
                        <MenuItem value={"casual"}>Casual</MenuItem>
                        <MenuItem value={"draftpvp"}>DraftPvP</MenuItem>
                    </Select>}
                    {admin &&<ActionIcon onClick={() => {GetReports();}} >
                        <RefreshIcon />
                    </ActionIcon>}
                </div>
                <div className={"flex flex-row h-12 w-full justify-center items-center text-xl"}>Reports</div>
            </Card>

            <div className={"flex flex-col w-full h-full bg-[#2e2e2e] overflow-hidden rounded-lg border border-gray-600"}>
                <Button p={0} height={120} w={"95%"} onClick={() => setActiveReport(null)} variant={(!activeReport ? "light" : "default")} className={"flex flex-row justify-center items-center my-1 ml-2"}>
                    Full Overview
                </Button>
                <div className="flex flex-col h-full w-full items-center gap-1.5 pl-2 pr-4 text-xs overflow-y-auto">
                {SortedReports && SortedReports.map((el, ind) =>
                    <div onClick={() => setActiveReport(el)} key={ind} className={"flex flex-row w-full text-xs h-auto justify-center cursor-pointer gap-2 rounded-lg border opacity-80 hover:bg-slate-600 hover:opacity-100 border-gray-600 px-2 items-center " + (activeReport && activeReport.id === el.id ? "bg-slate-500" : "")}>
                        <Badge size={"sm"} w={100} color="blue">{el.matchtype}</Badge>
                        <div className={"flex flex-col h-full w-full text-xs"}>
                            <p className={"flex flex-row w-full h-1/2 justify-start items-center"}>{el.PlayerOne + " vs " + el.PlayerTwo}</p>
                            <p className={"flex flex-row w-full h-1/2 justify-start items-center"}>{el.startTime}</p>
                        </div>
                    </div>)}
                </div>
            </div>

        </div>
    )
}