import React, {useEffect, useMemo, useState} from "react";
import {useDbContext} from "../contexts/dbContext";
import {bordercolor} from "../theme";
import Tooltip from "@mui/material/Tooltip";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {ArrowBack, Check, DeleteForever, EditOutlined, Print} from "@mui/icons-material";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ConfirmDialog from "./deleteconfirm";
import {Checkbox} from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
    ActionIcon,
    Center,
    CloseButton,
    Input,
    MultiSelect,
    SegmentedControl,
    Textarea,
    Button,
    Switch, Popover
} from '@mantine/core';
import {dbstyle} from "./dbstylings";
import { Tabs, rem } from '@mantine/core';
import { IconPhoto, IconMessageCircle, IconSettings } from '@tabler/icons-react';
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import SortIcon from "@mui/icons-material/Sort";
import {CheckmarkIcon} from "react-hot-toast";
import {DechManagerFilterMenu, DechManagerSortMenu} from "./sortmenu";
import {useAppContext} from "../contexts/appContext";
import {BarChart, PieChart, RadarChart} from "@mantine/charts";

export default function RightBar(props){
    const {maxmana, ManaCardCount, ManaIcons, ElementCosts, activeDeck, GetCardData, RemoveCard, SaveDeck, myDecks, addDeck, setActiveDeck, DeleteDeck, deckWindow, setDeckWindow, units, actions, resources} = useDbContext();
    const {cardsdate} = useAppContext();
    const [elementFilter, setElementFilter] = useState([]);
    const [openTab, setOpenTab] = useState("decks");
    const [showCards, setShowCards] = useState("main");
    const [editDeck, setEditDeck] = useState(false);
    const iconStyle = { width: rem(12), height: rem(12) };
    const [hoverId, setHoverID] = useState("");
    const [sortType, setSortType] = useState("CostDesc");
    const [exclusive, setExclusive] = useState(false);
    const [statsOpen, setStatsOpen] = useState(true);
    const [typeFilter, setTypeFilter] = useState("All");
    const [openStatTab, setOpenStatTab] = useState("stats");

    const [mainDeck, setMainDeck] = useState([]);

    useEffect(() => {
       if(activeDeck) FilterMainDeck();
    }, [activeDeck, elementFilter, sortType, exclusive, typeFilter]);


    async function FindCard(cardID, isMana){
        if(isMana) {
            let crd = null;
            for(let j = 0; j < resources.length; j++){
                if(resources[j]._id === cardID){
                    crd = resources[j];
                }
            }
            return crd;
        } else {
            let crd = null;
            for(let j = 0; j < units.length; j++){
                if(units[j]._id === cardID){
                    crd = units[j];
                    break;
                }
            }
            for(let j = 0; j < actions.length; j++){
                if(actions[j]._id === cardID){
                    crd = actions[j];
                    break;
                }
            }
            return crd;
        }
    }

    const FilterMainDeck = async() => {
        let crds = [];
        if (activeDeck.maindeck && activeDeck.maindeck.length > 0) {
            for (let i = 0; i < activeDeck.maindeck.length; i++) {
                await FindCard(activeDeck.maindeck[i].Id, false).then(crd => {
                    let card = activeDeck.maindeck[i];
                    let totalcost = 0;
                    for (let i = 0; i < crd.costs.length; i++) {
                        totalcost += crd.costs[i].amount;
                    }
                    card.costs = crd.costs;
                    card.totalcost = totalcost;
                    card.identity = crd.identity;
                    let vis = false;

                    if (typeFilter !== "All") {
                        if (card.type === typeFilter) {
                            vis = true;
                        }
                    } else {
                        vis = true;
                    }

                    if(vis) {
                        if (elementFilter.length > 0) {
                            if (exclusive) {
                                let fnd = false;
                                for (let j = 0; j < card.costs.length; j++) {
                                    if (j > 0 && !fnd) break;
                                    if (card.costs[j].element === "nonspecific") {
                                        if (elementFilter.includes("Neutral")) fnd = true;
                                    } else {
                                        if (elementFilter.includes(card.costs[j].element)) fnd = true;
                                    }
                                }
                                if (fnd) {
                                    crds.push(card);
                                }
                            } else {
                                let fnd = false;
                                for (let j = 0; j < card.costs.length; j++) {
                                    if (card.costs[j].element === "nonspecific") {
                                        if (elementFilter.includes("Neutral")) {
                                            fnd = true;
                                            break;
                                        }
                                    } else {
                                        if (elementFilter.includes(card.costs[j].element)) fnd = true;

                                        if (card.costs[j].element.includes("-")) {
                                            let els = card.costs[j].element.split("-");
                                            for (let i = 0; i < els.length; i++) {
                                                if (elementFilter.includes(els[i])) {
                                                    fnd = true;
                                                    break;
                                                }
                                            }
                                        } else {
                                            if (elementFilter.includes(card.costs[j].element)) {
                                                fnd = true;
                                                break;
                                            }
                                        }
                                    }
                                }
                                if (fnd) {
                                    crds.push(card);
                                }
                            }
                        } else {
                            crds.push(card);
                        }
                    }
                });
            }
            let ls = await SortMainDeck(crds);
            setMainDeck(ls);
        }
        else setMainDeck([]);
    }

     async function SortMainDeck(crds){
        switch(sortType){
            case "CostDesc":
                return crds.sort((a, b) => a.totalcost > b.totalcost ? -1 : 1);
            case "CostAsc":
                return crds.sort((a, b) => a.totalcost > b.totalcost ? -1 : 1).reverse();
            case "NameAsc":
                return crds.sort((a, b) => a.Name > b.Name ? -1 : 1).reverse();
            case "NameDesc":
                return crds.sort((a, b) => a.Name > b.Name ? -1 : 1);
            case "IdentityAsc":
                return crds.sort((a, b) => a.identity > b.identity ? -1 : 1).reverse();
            case "IdentityDesc":
                return crds.sort((a, b) => a.identity > b.identity ? -1 : 1);
            case "TypeU":
                return crds.sort((a, b) => a.type > b.type ? -1 : 1);
            case "TypeA":
                return crds.sort((a, b) => a.type > b.type ? -1 : 1).reverse();
            default:
                return crds.sort((a, b) => a.Name > b.Name ? -1 : 1).reverse();
        }
    }


    const calcManaCosts = useMemo(() => {
        const manaCosts = [];
        for (const card of activeDeck.maindeck) {
            const crd = card.type === "units" ? units.find(x => x._id === card.Id) : actions.find(x => x._id === card.Id);
            if (crd) {
                for (const cost of crd.costs) {
                    if (cost.element.includes("-")) {
                        const elements = cost.element.split("-");
                        for (const element of elements) {
                            const existingCost = manaCosts.find(mc => mc.element === element);
                            if (existingCost) {
                                existingCost.amount += cost.amount;
                            } else {
                                manaCosts.push({ element, amount: cost.amount });
                            }
                        }
                    } else {
                        const existingCost = manaCosts.find(mc => mc.element === cost.element);
                        if (existingCost) {
                            existingCost.amount += cost.amount;
                        } else {
                            manaCosts.push({ element: cost.element, amount: cost.amount });
                        }
                    }
                }
            }
        }
        return manaCosts;
    },[activeDeck, units, actions]);


    const unitCount = useMemo(() => {
        return activeDeck ? activeDeck.maindeck.filter(card => card.type === "units").length : 0;
    }, [activeDeck]);

    const actionCount = useMemo(() => {
        return activeDeck ? activeDeck.maindeck.filter(card => card.type === "actions").length : 0;
    }, [activeDeck]);

    const cardCount = useMemo(() => {
        return activeDeck && activeDeck.maindeck ? activeDeck.maindeck.reduce((sum, card) => sum + card.amount, 0) : 0;
    }, [activeDeck]);


    const pieData = useMemo(() => {
        return [
            { name: "Units", value: unitCount, color: 'blue.6' },
            { name: "Actions", value: actionCount, color: 'red.6' }
        ];
    }, [unitCount, actionCount]);


    return (
    <div className={"flex flex-col w-[500px] h-full overflow-y-hidden border-l " + dbstyle.headercolors}>
            {openTab === "decks" ?
                <DeckHeader setEditDeck={setEditDeck} openTab={openTab} setOpenTab={setOpenTab} editDeck={editDeck}/>
                :
                <div className={"flex-row flex justify-center text-lg font-semibold items-center px-2 w-full h-[70px] border-b " + bordercolor}>
                    <Tooltip title={"Cancel"}>
                        <ActionIcon size={40} variant="default" aria-label="Close" onClick={() => {
                            setOpenTab("decks");
                        }}>
                            <ArrowBack />
                        </ActionIcon>
                    </Tooltip>
                    <p className={"flex flex-row w-hull w-full justify-center items-center"}> Select deck</p>
                    <div className={"flex flex-row h-full w-10"}></div>
                </div>
            }
            {openTab === "select" &&
                <div className={"flex flex-col w-full h-full overflow-y-hidden"}>
                    <div className={"flex flex-col w-full overflow-hidden h-full border-b " + bordercolor}>
                        <div className={"flex flex-col w-full h-full justify-start items-center overflow-y-auto"}>
                            {myDecks && myDecks.map((deck, ind) => (
                                <DeckListEntry key={ind} editDeck={editDeck} setEditDeck={setEditDeck} deck={deck}/>
                            ))}
                        </div>
                    </div>
                    <div className={"flex flex-row h-[100px] w-full justify-around items-center"}>
                        <div className={"flex flex-row h-full items-center justify-around w-full"}>
                            <Button leftSection={<AddCircleOutlineIcon/>} variant="default" onClick={() => {
                                let nd = {}
                                nd.deckName = "New Deck";
                                nd.publicshare = true;
                                nd.description = "";
                                nd.maindeck = [];
                                nd.bench = [];
                                nd.origin = -1;
                                nd.resourcesdeck = [];
                                addDeck(nd);
                            }}>Create New</Button>
                            <Button variant="default" onClick={() =>  setDeckWindow(!deckWindow)}>Public Decks</Button>
                        </div>
                    </div>
                </div>
            }

            {openTab === "decks" &&
                <div className={"flex flex-col w-full h-full overflow-y-hidden"}>
                    <div className={"flex flex-row w-full h-[50px] overflow-y-hidden justify-center items-center border-b " + bordercolor}>
                        <div className={"flex flex-col w-1/5 items-center justify-around"}>
                            <DechManagerSortMenu sortType={sortType} setSortType={setSortType} />
                        </div>
                        <div className={"flex flex-col w-4/5 items-center justify-around"}>
                            <SegmentedControl
                                value={showCards}
                                onChange={setShowCards}
                                data={[
                                    {
                                        label: (
                                            <Tooltip title={"Click to show units and actions in deck."}>
                                                <Center style={{gap: 10}}>
                                                    <span>Main Deck</span>
                                                </Center>
                                            </Tooltip>
                                        ), value: 'main'
                                    },
                                    {
                                        label: (
                                            <Tooltip title={"Click to show mana cards in deck."}>
                                                <Center style={{gap: 10}}>
                                                    <span>Mana Deck</span>
                                                </Center>
                                            </Tooltip>
                                        ), value: 'mana'
                                    },
                                    {
                                        label: (
                                            <Tooltip title={"Click to show bench cards in deck."}>
                                                <Center style={{gap: 10}}>
                                                    <span>Bench</span>
                                                </Center>
                                            </Tooltip>
                                        ), value: 'bench'
                                    },
                                ]}
                            />
                        </div>
                        <div className={"flex flex-col w-1/5 items-center justify-around"}>
                            <DechManagerFilterMenu  typeFilter={typeFilter} setTypeFilter={setTypeFilter} exclusive={exclusive} setExclusive={setExclusive} setElementFilter={setElementFilter} elementFilter={elementFilter} />
                        </div>
                    </div>
                    {showCards !== "bench" && <div
                        className={"flex flex-row h-[45px] justify-center select-none py-0.5 items-center gap-0.5 w-full border-b " + bordercolor}>
                        {showCards === "main" && <>
                            <ElementCounter str={"Solar"} setElementFilter={setElementFilter}
                                            elementFilter={elementFilter}/>
                            <ElementCounter str={"Lunar"} setElementFilter={setElementFilter}
                                            elementFilter={elementFilter}/>
                            <ElementCounter str={"Flame"} setElementFilter={setElementFilter}
                                            elementFilter={elementFilter}/>
                            <ElementCounter str={"Water"} setElementFilter={setElementFilter}
                                            elementFilter={elementFilter}/>
                            <ElementCounter str={"Earth"} setElementFilter={setElementFilter}
                                        elementFilter={elementFilter}/>
                            <ElementCounter str={"Metal"} setElementFilter={setElementFilter}
                                        elementFilter={elementFilter}/>
                            <ElementCounter str={"all"} setElementFilter={setElementFilter}
                                            elementFilter={elementFilter}/>
                        </>}
                        {showCards === "mana" && <>
                            <ManaCounter str={"solar"} setElementFilter={setElementFilter}
                                         elementFilter={elementFilter}/>
                            <ManaCounter str={"lunar"} setElementFilter={setElementFilter}
                                         elementFilter={elementFilter}/>
                            <ManaCounter str={"flame"} setElementFilter={setElementFilter}
                                            elementFilter={elementFilter}/>
                            <ManaCounter str={"water"} setElementFilter={setElementFilter}
                                         elementFilter={elementFilter}/>
                            <ManaCounter str={"earth"} setElementFilter={setElementFilter}
                                         elementFilter={elementFilter}/>
                            <ManaCounter str={"metal"} setElementFilter={setElementFilter}
                                            elementFilter={elementFilter}/>
                            <ManaCounter str={"all"} setElementFilter={setElementFilter}
                                         elementFilter={elementFilter}/>
                        </>}
                    </div>}
                    <div className={"flex flex-col w-full overflow-hidden h-full border-b " + bordercolor}>
                        <Popover width={430} styles={{}} position="left-start" opened={hoverId !== ""} withArrow shadow="md">
                            <Popover.Target>
                                <div className={"flex flex-col w-full h-full justify-start items-center overflow-y-auto"}>
                                    {showCards === "mana" && activeDeck && activeDeck.resourcesdeck && activeDeck.resourcesdeck.length > 0 && activeDeck.resourcesdeck.map((crd, ind) => (
                                        <div key={ind} onMouseLeave={() => {
                                            setHoverID("");
                                        }} onMouseEnter={() => {
                                            setHoverID(crd.Id);
                                        }}  className={"flex flex-row text-sm px-2 h-12 hover:bg-[#615C5C10] w-full py-4 justify-start items-center border-b " + bordercolor + (ind % 2 === 0 ? " bg-[#615C5C30] " : " bg-[#615C5C52] ")}>
                                            <div className={"flex flex-row w-2/3 h-full justify-start pl-1 text-sm items-center"}>
                                                <p>{crd.amount}x</p>
                                                <p className={"w-8"}></p>
                                                {crd.Name}</div>
                                            <div className={"flex flex-row w-1/3 h-8 gap-1 justify-start items-center"}>
                                            <ManaIcons ele={GetCardData(crd.Id).identity.toLowerCase()}/>
                                            </div>
                                        </div>
                                    ))}

                                    {showCards === "main" && mainDeck && mainDeck.length > 0 && mainDeck.map((crd, ind) => (
                                        <CardListEntry setHoverID={setHoverID} key={ind} crd={crd} ind={ind} bench={false}  />
                                    ))}

                                    {showCards === "bench" && activeDeck && activeDeck.bench && activeDeck.bench.length > 0 && activeDeck.bench.map((crd, ind) => (
                                        <CardListEntry setHoverID={setHoverID} key={ind} crd={crd} ind={ind} bench={true}  />
                                    ))}
                                </div>
                            </Popover.Target>
                            <Popover.Dropdown style={{pointerEvents: 'none'}}>
                                <div className={"flex flex-col min-h-40 h-auto w-[400px] z-50 p-3 overflow-hidden self-center justify-self-center bg-[#0a0f13] rounded-2xl border " + bordercolor}>
                                    <img className={"h-auto w-full rounded-2xl"} alt={"preview" + hoverId}
                                         src={"/cards/" + hoverId + ".png?key=" + cardsdate}/>
                                </div>
                            </Popover.Dropdown>
                        </Popover>
                    </div>

                    {!statsOpen ?
                        <div className={"flex flex-col w-full h-[50px] overflow-hidden"}>
                            <Button onClick={() => {setStatsOpen(true)}}>Details</Button>
                        </div>
                        :
                    <div className={"flex flex-col w-full h-[500px] overflow-hidden"}>
                        <div className={"flex flex-row h-[50px] w-full justify-around items-center overflow-hidden"}>
                            <div className={"flex flex-row justify-start items-center gap-2 w-auto h-full"}>
                                <Button variant={openStatTab === "stats" ? "filled" : "default"} onClick={() => {setOpenStatTab("stats")}}>
                                Stats
                            </Button>
                                <Button variant={openStatTab === "bars" ? "filled" : "default"} onClick={() => {setOpenStatTab("bars")}}>
                                    Bars
                                </Button>
                                <Button variant={openStatTab === "radar" ? "filled" : "default"} onClick={() => {setOpenStatTab("radar")}}>
                                    Radar
                                </Button>
                            </div>
                            <Button onClick={() => {setStatsOpen(false)}}>Hide</Button>
                        </div>
                        <div className={"flex flex-row w-full h-full p-2 overflow-hidden"}>
                            {openStatTab === "radar" && <div>
                                <RadarChart
                                    h={200}
                                    w={200}
                                    minWidth={0}
                                    minHeight={0}
                                    data={calcManaCosts}
                                    dataKey="element"
                                    withPolarRadiusAxis
                                    series={[{ name: 'amount', color: 'blue.4', opacity: 0.2 }]}
                                />
                            </div>}

                            {openStatTab === "stats" &&
                                <>
                                    <BarChart
                                        minWidth={0}
                                        minHeight={0}
                                        h={200}
                                        w={200}
                                        data={pieData}
                                        dataKey="name"
                                        series={[
                                            { name: 'value', color: 'violet.6' },
                                        ]}
                                        tickLine="y"
                                    />
                                    <PieChart h={200} w={200} minWidth={0} minHeight={0} data={pieData} withLabelsLine labelsPosition="outside" labelsType="value" withLabels withTooltip tooltipDataSource="segment" mx="auto" />
                                    </>
                                }
                            {openStatTab === "bars" &&
                                <div>
                                    <BarChart
                                        h={200}
                                        w={200}
                                        minWidth={0}
                                        minHeight={0}
                                        data={calcManaCosts}
                                        dataKey="element"
                                        series={[
                                            { name: 'amount', color: 'violet.6' },
                                        ]}
                                        tickLine="y"
                                    />
                                </div>}
                        </div>
                    </div>}

                    <div className={"flex flex-row h-[50px] w-full justify-between items-center border border-t overflow-hidden " + bordercolor}>
                        <div className={"flex flex-row w-full h-full justify-center items-center"}>
                            <div className={"flex flex-row w-1/2 gap-2 h-full justify-center items-center"}>
                                <p>Units: {unitCount}</p>
                                <p>Actions: {actionCount}</p>
                            </div>
                            <div className={"flex flex-row w-1/2 h-full justify-center items-center text-sm"}>
                                {showCards === "main" &&
                                    <div className={"flex flex-row w-full h-full justify-around items-center"}>

                                        <p>{cardCount}/50 cards</p>
                                    </div>
                                }
                                {showCards === "mana" &&<div className={"flex flex-row w-full h-full justify-center items-center"}>{ManaCardCount()}/{maxmana}
                                    cards</div>}
                                {showCards === "bench" &&<div className={"flex flex-row w-full h-full justify-center items-center"}>{activeDeck ? activeDeck.bench.length : 0}/10
                                    cards</div>}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

function DeckHeader(props) {
    const {activeDeck, SaveDeck, setActiveDeck} = useDbContext();
    const {setEditDeck, openTab, setOpenTab, editDeck} = props;
    const [deckDescription, setDeckDescription] = useState("");
    const [tempName, setTempName] = useState("");
    const [pshare, setPShare] = useState(false);

    useEffect(() => {
        if (activeDeck) {
            setDeckDescription(activeDeck.description);
            setTempName(activeDeck.deckName);
            setPShare(activeDeck.publicshare);
        }
    }, [activeDeck]);

    function Save() {
        setActiveDeck(prev => {
            const newDeck = { ...prev };
            newDeck.description = deckDescription;
            newDeck.deckName = tempName;
            newDeck.publicshare = pshare;
            if(activeDeck.description !== newDeck.description || activeDeck.deckName !== newDeck.deckName || newDeck.publicshare !== activeDeck.publicshare) SaveDeck(newDeck);
            return newDeck;
        })
    }

    function GetCardList(){
        let ls = [];
        for(let i = 0; i < activeDeck.maindeck.length; i++) {
            let nc = { name: activeDeck.maindeck[i].Name, link: "https://play.manacaster.com/cards/" + activeDeck.maindeck[i].Id + ".png" };
            ls.push(nc);
        }
        let ls2 = [];
        for(let i = 0; i < activeDeck.resourcesdeck.length; i++) {
            let nc = { name: activeDeck.resourcesdeck[i].Name, link: "https://play.manacaster.com/cards/" + activeDeck.resourcesdeck[i].Id + ".png" };
            ls2.push(nc);
        }
        return JSON.stringify({maindeck: ls, manadeck: ls2});
    }

    if(editDeck) {
        return (
            <div className={"flex flex-col w-full h-[350px] border-b " + bordercolor}>
                <div className={"flex-row flex justify-center text-lg font-semibold items-center w-full h-[80px] px-4"}>
                    <Input placeholder="Deck Name"
                           style={{width:'100%'}}
                               label="Deck Name"
                               value={tempName}
                               onChange={(event) => setTempName(event.currentTarget.value)}
                               onBlur={(e) => {
                                   Save();
                               }}
                    />
                </div>
                <div className={"flex flex-row h-[70px] w-full px-4"}>
                    <Textarea
                       style={{width:'100%', height:"100%"}}
                        onChange={(e) => setDeckDescription(e.currentTarget.value)}
                        value={deckDescription}
                        onBlur={(e) => {
                            Save();
                        }}
                    />
                    </div>
                    <div className={"flex flex-row h-12 w-full justify-around items-center"}>
                        <Switch
                            label="Share Publicly"
                            checked={pshare}
                            onChange={(event) => {
                                setPShare(event.currentTarget.checked);
                                activeDeck.publicshare = event.currentTarget.checked;
                                SaveDeck();
                            }
                        }
                        />
                        <Button variant="default" onClick={() => setEditDeck(null)}>Close Edit</Button>
                    </div>
                <div className={"flex flex-row h-12 w-full justify-center items-center"}>
                    <Button variant="default" onClick={() => {navigator.clipboard.writeText(GetCardList())}}>
                        Cards list to Clipboard
                    </Button>
                </div>
            </div>
        )
    } else return (
        <div className={"flex flex-row items-center px-2 w-full h-[70px] border-b " + bordercolor}>
            <Tooltip title={"Swap to deck selection"}>
                <ActionIcon size={40} variant="default" aria-label="Sort menu" onClick={() => {
                    if (openTab === "decks") setOpenTab("select");
                }}>
                    <SwapHorizIcon/>
                </ActionIcon>
            </Tooltip>

            <div className={"flex-row flex justify-center text-lg font-semibold items-center w-full h-full"}>
                {activeDeck.deckName}
            </div>

            <ActionIcon size={40} variant="default" aria-label="Sort menu" onClick={() => {
                setEditDeck(!editDeck);
            }}>
                <EditOutlined/>
            </ActionIcon>
        </div>
    )
}

function DeckListEntry(props) {
    const {activeDeck, setActiveDeck, DeleteDeck} = useDbContext();
    const {deck, editDeck} = props;

    function DeckColor() {
        if (editDeck === deck) return " bg-slate-500 bg-opacity-50"
        if (activeDeck && activeDeck.id === deck.id) return " bg-[#615C5C52] ";
       else return " bg-[#615C5C30] ";
    }

    return (

    <div key={deck.id}
         className={(editDeck === deck ? "h-[200px] " : "h-14 ") + " flex flex-col hover:bg-opacity-70 justify-center items-center w-full px-2 border-b " + bordercolor + DeckColor()}>
                <div className={"flex flex-row h-14 w-full "}>
                    <div onClick={() => setActiveDeck(deck)} className={"flex pl-4 w-10 h-full justify-center items-center"}>
                        <input type="radio" name="radio-2" className="radio radio-primary"
                              onChange={(e) => { if(e.currentTarget.checked) setActiveDeck(deck)}} checked={activeDeck && activeDeck.id === deck.id}/>
                        </div>
                    <div className={"flex w-full h-full justify-center items-center"} onClick={() => setActiveDeck(deck)}>
                    {deck.deckName}
                        </div>
                    <ConfirmDialog deckEntry={deck} DeleteDeck={DeleteDeck}/>
                </div>
            </div>
    )
}

function CardListEntry(props) {
    const {ind, crd, bench, setHoverID} = props;
    const {GetCardData, ElementCosts, RemoveCard, activeDeck, SaveDeck, ZeroCard, UnZeroCard, RemoveBench} = useDbContext();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorPosX, setAnchorPosX] = useState(0);
    const [anchorPosY, setAnchorPosY] = useState(0);
    const open = Boolean(anchorEl);

    let card = GetCardData(crd.Id);

    const handleClick = (event) => {
        if(event.button === 2){
            setAnchorEl(event.currentTarget);
            setAnchorPosX(event.clientX);
            setAnchorPosY(event.clientY);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRemove = () => {
        if(bench) {
            if (RemoveBench(crd) && activeDeck.id !== "") SaveDeck();
            setAnchorEl(null);
        } else {
            if (RemoveCard(crd) && activeDeck.id !== "") SaveDeck();
            setAnchorEl(null);
        }
    }

    const handleZero = () => {
        if (ZeroCard(crd) && activeDeck.id !== "") SaveDeck();
        setAnchorEl(null);
    }

    const handleRestoreCard = () => {
        if (UnZeroCard(crd) && activeDeck.id !== "") SaveDeck();
        setAnchorEl(null);
    }

    const col = "rgba(97,92,92,0.19)";
    const colo =  "rgba(0,118,204,0.19)"
    const colo2 =  "rgba(1,122,211,0.19)"

    function CardColor() {
        if(crd.type === "units") {
            if(crd.amount > 0) return (ind % 2 === 0 ? " bg-[#0076CC30] " : " bg-[#017AD340] ")
            else return " bg-red-700";
        } else {
            if(crd.amount > 0) return (ind % 2 === 0 ? " bg-[#933F3F30] " : " bg-[#933F3F40] ")
            else return " bg-red-700";
        }
    }

    return (
        <>
            <div key={ind} onMouseLeave={() => {
                setHoverID("");
            }} onMouseEnter={() => {
                setHoverID(crd.Id);
            }}
                 id="card-button"
                 aria-controls={open ? 'card-menu' : undefined}
                 aria-haspopup="true"
                 aria-expanded={open ? 'true' : undefined}
                 onMouseDown={handleClick}
                 className={"flex px-2 flex-row text-sm hover:opacity-100 opacity-80 h-14 w-full py-2 justify-start items-center border-b " + bordercolor + CardColor()}>
                <p className={"flex flex-row w-2/3 h-full justify-start items-center pl-1"}>{crd.Name}</p>
                <div className={"flex flex-row w-1/3 h-8 gap-1 justify-start items-center"}>
                    {card && card.costs && card.costs.map((el) => (
                        <ElementCosts key={crd.Id + el.element} element={el.element}
                                      amount={el.amount}/>
                    ))}
                </div>
            </div>
            <Menu
                id="card-menu"
                anchorReference={"anchorPosition"}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'card-button',
                }}
                anchorPosition={{ left: anchorPosX, top: anchorPosY }}
            >
                {!bench && (crd.amount > 0 ? <MenuItem onClick={handleZero}>Set to 0</MenuItem > : <MenuItem onClick={handleRestoreCard}>Restore</MenuItem>)}
                <MenuItem onClick={handleRemove}>Remove</MenuItem>
            </Menu>
        </>
    );
}


const selbuttonCol = "bg-[#4CB190C3]"
const buttonCol = " bg-[#3b6f5e26]"

function ElementCounter(props) {
    const {elementFilter, str} = props;
    const {TotalCosts} = useDbContext();

    return (
        <div className={"flex flex-row h-full w-[14%] justify-around items-center hover:bg-opacity-60 text-xs " + (str !== "all" ? (elementFilter.includes(str) ? selbuttonCol : buttonCol) : (elementFilter.length < 1 ? selbuttonCol : buttonCol))}>
            {str !== "all" && <img className={"h-5 w-5"} src={"/images/" + str.toLowerCase() + ".png"} alt={str.toLowerCase()}/>}
            <TotalCosts str={str.toLowerCase()}/>
        </div>
    )
}

function ManaCounter(props) {
    const {elementFilter, str, setElementFilter} = props;
    const {ResourceAmount} = useDbContext();

        return (
            <div className={"flex flex-row h-full w-[14%] text-xs justify-around items-center hover:bg-opacity-60 " + buttonCol}>
                {str !== "all" && <img className={"h-5 w-5"} src={"/images/" + str + ".png"} alt={str}/>}
                <ResourceAmount str={str}/>
        </div>
    )
}