import {createContext, useContext, useEffect, useState, useCallback, useMemo} from "react";
import Axios from "axios";
import {dblink} from "../App";
import toast from "react-hot-toast";
import {getISOtime, supabase} from "../utils/supabase";
import {cardsOnlyAtom, profileAtom, sessionAtom, userIdAtom} from "../atoms/appAtoms";
import {useAtom, useAtomValue} from "jotai/index";
import {
    actionsAtom,
    activeDeck2Atom,
    activeDeckAtom, cardsPerPageAtom, currentPageAtom,
    decksAtom, deckWindowAtom, elementsAtom, identitiesAtom,
    isSavingAtom, keywordsAtom, loadingDataAtom,
    myDecksAtom, pagesAtom, resourcesAtom,
    unitsAtom, visibleCardsAtom
} from "../atoms/deckBuilderAtoms";
const DbContext = createContext({});

let cs = localStorage.getItem("cardsize");
let cp = localStorage.getItem("cardspage");
let ad = localStorage.getItem("activedeck");

const DbContextProvider = ({ children }) => {
    const profile = useAtomValue(profileAtom);
    const id = useAtomValue(userIdAtom);
    const cardsOnly = useAtomValue(cardsOnlyAtom);
    const session = useAtomValue(sessionAtom);
    const [activeDeck, setActiveDeck] = useAtom(activeDeckAtom)
    const [activeDeck2, setActiveDeck2]  = useAtom(activeDeck2Atom)
    const [myDecks, setMyDecks] = useAtom(myDecksAtom);
    const [decks, setDecks] = useAtom(decksAtom);
    const [units, setUnits] = useAtom(unitsAtom);
    const [actions, setActions] = useAtom(actionsAtom);
    const [resources, setResources] = useAtom(resourcesAtom);
    const [cardSize, setCardSize] = useState(cs !== null ? parseFloat(cs) : 0.28);
    const [cardsPerPage, setCardsPerPage] = useAtom(cardsPerPageAtom);
    const [keywords, setKeywords] = useAtom(keywordsAtom);
    const [elements, setElements] = useAtom(elementsAtom);
    const [identities, setIdentities] = useAtom(identitiesAtom);
    const [previewDeck, setPreviewDeck] = useState({});
    const [previewing, setPreviewing] = useState(false);
    const [ad, setAd] = useState(localStorage.getItem("activedeck") ? parseInt(localStorage.getItem("activedeck")): -1);
    const [aD2, setaD2] = useState(0);
    const [starterScreen, setStarterScreen] = useState(false);
    const [deckWindow, setDeckWindow] = useAtom(deckWindowAtom);
    const [cardbacks, setCardbacks] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [currentPage, setCurrentPage] = useAtom(currentPageAtom);
    const [currentDeckPageLabel, setCurrentDeckPageLabel] = useState(1);
    const [IdentityFilter, setIdentityFilter] = useState([]);
    const [identity, setIdentity] = useState("And");
    const [keywordFilter, setKeywordFilter] = useState([]);
    const [keywordNot, setKeywordNot] = useState(false);
    const [keyword, setKeyword] = useState("And");
    const [DisplayMode, swapDisplayMode] = useState("All");
    const [sortType, setSortType] = useState("CostAsc");
    const [edition, setEdition] = useState("Base Set");
    const [deckfilterActive, setDeckfilterActive] = useState(false);
    const [InDeck, SetInDeck] = useState("all");
    const [editions, setEditions] = useState([]);
    const maxmain = 1;
    const maxmana = 13;
    const [decksLoading, setDecksLoading] = useState(true);
    const [showOwn, setShowOwn] = useState(false);
    const [hideAdded, setHideAdded] = useState(true);
    const [hideIncomplete, setHideIncomplete] = useState(true);
    const [deckst, setDeckSt] = useState("");
    const [decksPerPage, setDecksPerPage] = useState(10);
    const [currentDeckPage, setCurrentDeckPage] = useState(0);
    const [deckPages, setDeckPages] = useState([]);
    const [allCards, setAllCards] = useState([]);
    const [cardTooltips, setCardTooltips] = useState(false);
    const [identityNot, setIdentityNot] = useState(false);
    const [loadingData, setLoadingData] = useAtom(loadingDataAtom);
    const [totalVisibleCards, setTotalVisibleCards] = useAtom(visibleCardsAtom);

    //#region useEffects
    useEffect(() => {
        getcards();
        if(id !== ""){
            if (!cardsOnly && session) {
                setDecksLoading(true);
                getmydecks();
                getdecks();
            } else if (!cardsOnly) getdecks();
            else setDecksLoading(false);
        }
    }, [id]);

    useEffect(() => {
        if (cardsOnly) return;
        if(myDecks.length > 0 && !activeDeck && ad !== -1){
            let fnd = false;
            myDecks.forEach(el => {
                if (el.id === ad) {
                    setActiveDeck(el);
                    setStarterScreen(false);
                    fnd = true;
                }
            });
            if(!fnd) setActiveDeck(myDecks[0]);
        } else if (myDecks.length > 0) {
            if(activeDeck){
                myDecks.forEach(el => {
                    if (el.id === activeDeck.id) {
                        setActiveDeck(el);
                        setStarterScreen(false);
                    }
                });
            } else {
                if(ad && ad > -1){
                    myDecks.forEach(el => {
                        if (el.id === ad) {
                            setActiveDeck(el);
                            setStarterScreen(false);
                        }
                    });
                } else setActiveDeck(myDecks[0]);
            }
        } else setActiveDeck(null);
    }, [myDecks]);

    useEffect(() => {
       if(activeDeck) {
           localStorage.setItem("activedeck", activeDeck.id);
       }
    }, [activeDeck]);


    useEffect(() => {
        if(totalVisibleCards !== DisplayCards().length) setTotalVisibleCards(DisplayCards().length);
        const totalPages = Math.ceil(totalVisibleCards / cardsPerPage);
        if (currentPage > totalPages) {
            setCurrentPage(totalPages);
        } else if(currentPage === 0) setCurrentPage(1);

    }, [totalVisibleCards, cardsPerPage, keywordFilter, IdentityFilter, searchText, identityNot, keywordNot, keyword]);

    //#endregion

    //#region Data collections
    async function getcards() {
        const { data, error } = await supabase
            .from('carddata')
            .select()
            .eq("id", 3)
        if(error){
            console.log(error);
        } else {
            setUnits(data[0].units);
            setActions(data[0].actions);
            setResources(data[0].resources);
            setKeywords(data[0].keywords);
            setElements(data[0].elements);
            setIdentities(data[0].identities);
            setCardbacks(data[0].cardBacks);
            let ls = [];
            data[0].units.forEach((el)=> { if(!ls.includes(el.edition)) ls.push(el.edition); });
            data[0].actions.forEach((el)=> { if(!ls.includes(el.edition)) ls.push(el.edition); });
            setEditions(ls);
        }
    }

    async function getdecks() {
        if (cardsOnly) return;
        const { data, error } = await supabase
            .from('decks')
            .select()
            .eq('publicshare', true)
        if(error){

        } else {
            setDecks(data);
        }
    }

   async function getmydecks() {
        if (cardsOnly) return;
        if (!session || !id) {
            setMyDecks([]);
            setDecksLoading(false);
            return;
        }
        const { data, error } = await supabase
            .from('decks')
            .select()
            .eq("user_id", id)
        if(error){

        } else {
            if(data && data.length < 1){
                let nd = {};
                nd.user_id = id;
                nd.deckName = "New Deck";
                nd.ownerName = profile.username;
                nd.upvotes = [];
                nd.maindeck = [];
                nd.resourcesdeck = [];
                nd.description = "";
                nd.publicshare = true;
                nd.bench = [];
                nd.origin = -1;
                nd.downvotes = [];
                nd.lastsave = getISOtime();
                nd.cardback = "64d761a0c97e5142e6a0c3dc";
                const {error} = await supabase.from('decks').insert(nd);
                if(error){
                    toast.error("Failed to add the deck " + nd.deckName + " to your decks list.", {position: "bottom-right"});
                } else {
                    getmydecks();
                    toast.success("Successfully added the deck " + nd.deckName + " to your decks list.", {position: "bottom-right"});
                }
            } else {
                setMyDecks(data);
                setDecksLoading(false);
            }
        }
    }

    //#endregion

    //#region Deck interaction
    async function addDeck(deck) {
        if (cardsOnly) return;
        if (!session) return;
        let nd = deck;
        nd.user_id = id;
        nd.ownerName = profile.username;
        nd.upvotes = [];
        nd.downvotes = [];
        nd.origin = deck.origin;
        nd.lastsave = getISOtime();
        nd.cardback = "64d761a0c97e5142e6a0c3dc";
        const {error} = await supabase.from('decks').insert(nd);
        if(error){
            toast.error("Failed to add the deck " + nd.deckName + " to your decks list.", {position: "bottom-right"});
        } else {
            getmydecks();
            toast.success("Successfully added the deck " + nd.deckName + " to your decks list.", {position: "bottom-right"});
        }
    }

    function HasDeck(deckid){
        let fnd = false;
        if(myDecks && myDecks.length > 0){
            for(let i = 0; i < myDecks.length; i++){
                if(myDecks[i].origin === deckid) fnd = true;
            }
        }
        return fnd;
    }

    function OwnDeck(deckid){
        let fnd = false;
        if(myDecks && myDecks.length > 0){
            for(let i = 0; i < myDecks.length; i++){
                if(myDecks[i].id === deckid) fnd = true;
            }
        }
        return fnd;
    }
    
    // Replace the existing SaveDeck function with this optimized version
    async function SaveDeck(nd) {
        if (!session || cardsOnly || !nd) return;
        try {
          const clone = (({id, user_id, created_at, ...o}) => o)(nd);
          clone.lastsave = getISOtime();
    
          const { error } = await supabase
            .from('decks')
            .update(clone)
            .eq('id', nd.id);
          if (error) {
            toast.error(`Failed to save deck "${nd.deckName}": ${error.message}`, {
              position: "bottom-right"
            });
          }
        } catch (err) {
          toast.error(`Error saving deck: ${err.message}`, {
            position: "bottom-right" 
          });
        } finally {
            await getmydecks();
            setActiveDeck(nd);
        }
    };

    function TotalCards(){
        if(!DisplayCards() || DisplayCards().length < 1) return 0;
        else return DisplayCards().length;
        /*
        let am = 0;
        if(!pages || pages.length < 1) return 0;
        for(let i = 0; i < pages.length; i++){
            am ++;
        }
        return am;

         */
    }

    async function DeleteDeck(deck) {
        if (cardsOnly) return;
        if (!session) return;

        const { error } = await supabase
            .from('decks')
            .delete()
            .eq('id', deck.id)
        
                if (error) {
                    if (activeDeck.id === deck.id) setActiveDeck(null);
                    toast.error("Failed to delete the deck.", {position: "bottom-right"});
                } else {
                    if (activeDeck.id === deck.id) setActiveDeck(null);
                    getdecks();
                    getmydecks();
                    toast.success("Successfully deleted the deck.", {position: "bottom-right"});
                }
    }

    function SetResourceAmount(card, amount, cb){
        let avai = maxmana - totalAmountResources();
        if (avai >= amount) {
            let fnd = false;
            for (let i = 0; i < activeDeck.resourcesdeck.length; i++) {
                let crd = activeDeck.resourcesdeck[i];
                if (crd.Id === card.Id) {
                    crd.amount = amount;
                    fnd = true;
                    return cb(amount);
                }
            }
            if (!fnd) {
                let crdentry = {};
                crdentry.Id = card.Id;
                crdentry.amount = amount;
                crdentry.Name = card.Name;
                crdentry.type = "resources";
                crdentry.version = 0;
                activeDeck.resourcesdeck.push(crdentry);
                return cb(amount);
            }
        } else if(avai > 0) {
            let fnd = false;
            for (let i = 0; i < activeDeck.resourcesdeck.length; i++) {
                let crd = activeDeck.resourcesdeck[i];
                if (crd.Id === card.Id) {
                    crd.amount = avai;
                    fnd = true;
                    return cb(avai);
                }
            }
            if (!fnd) {
                let crdentry = {};
                crdentry.Id = card.Id;
                crdentry.amount = avai;
                crdentry.Name = card.Name;
                crdentry.type = "resources";
                crdentry.version = 0;
                activeDeck.resourcesdeck.push(crdentry);
                return cb(avai);
            }
        } else return cb(0);
    }

    function AddCard(card) {
        if (cardsOnly || !session) return;

            const newDeck = { ...activeDeck };
            let cardAdded = false;
            
            if (card.type === "resources") {
                if (totalAmountResources() >= maxmana) return;
    
                newDeck.resourcesdeck = [...activeDeck.resourcesdeck];
                const existingCardIndex = newDeck.resourcesdeck.findIndex(c => c.Id === card.Id);
                
                if (existingCardIndex !== -1) {
                    newDeck.resourcesdeck[existingCardIndex] = {
                        ...newDeck.resourcesdeck[existingCardIndex],
                        amount: newDeck.resourcesdeck[existingCardIndex].amount + 1
                    };
                    cardAdded = true;
                } else {
                    newDeck.resourcesdeck.push({
                        Id: card.Id,
                        amount: 1,
                        Name: card.Name,
                        type: "resources",
                        version: 0
                    });
                    cardAdded = true;
                }
            } else {
                if (totalAmountMain() >= 50) return;
    
                newDeck.maindeck = [...activeDeck.maindeck];
                const existingCardIndex = newDeck.maindeck.findIndex(c => c.Id === card.Id);
                
                if (existingCardIndex !== -1) {
                    if (newDeck.maindeck[existingCardIndex].amount >= maxmain) return;
                    
                    newDeck.maindeck[existingCardIndex] = {
                        ...newDeck.maindeck[existingCardIndex],
                        amount: newDeck.maindeck[existingCardIndex].amount + 1
                    };
                    cardAdded = true;
                } else {
                    newDeck.maindeck.push({
                        Id: card.Id,
                        amount: 1,
                        Name: card.Name,
                        type: card.type,
                        version: 0
                    });
                    cardAdded = true;
                }
            }
    
            if (cardAdded && newDeck.id !== "") {
                SaveDeck(newDeck);
            }
    };

    function AddBench(card) {
        if (cardsOnly || !session) return false;

        setActiveDeck(prev => {
            if (!prev) return prev;
            
            const newDeck = { ...prev };
            newDeck.bench = [...(prev.bench || [])];
            
            // Check if card already exists in bench
            const existingCardIndex = newDeck.bench.findIndex(c => c.Id === card.Id);
            
            if (existingCardIndex !== -1) {
                if (newDeck.bench[existingCardIndex].amount >= 4) return prev;
                
                newDeck.bench[existingCardIndex] = {
                    ...newDeck.bench[existingCardIndex],
                    amount: newDeck.bench[existingCardIndex].amount + 1
                };
            } else {
                newDeck.bench.push({
                    Id: card.Id,
                    amount: 1,
                    Name: card.Name,
                    type: card.type,
                    version: 0
                });
            }

            if (newDeck.id !== "") {
                SaveDeck(newDeck);
            }
            
            return newDeck;
        });

        return true;
    };

    function RemoveCard(card) {
        if (cardsOnly || !session) return;
        const newDeck = { ...activeDeck };
        let cardRemoved = false;

        if (card.type === "resources") {
            newDeck.resourcesdeck = [...activeDeck.resourcesdeck];
            const cardIndex = newDeck.resourcesdeck.findIndex(c => c.Id === card.Id);

            if (cardIndex !== -1) {
                if (newDeck.resourcesdeck[cardIndex].amount > 1) {
                    newDeck.resourcesdeck[cardIndex] = {
                        ...newDeck.resourcesdeck[cardIndex],
                        amount: newDeck.resourcesdeck[cardIndex].amount - 1
                    };
                } else {
                    newDeck.resourcesdeck.splice(cardIndex, 1);
                }
                cardRemoved = true;
            }
        } else {
            newDeck.maindeck = [...activeDeck.maindeck];
            const cardIndex = newDeck.maindeck.findIndex(c => c.Id === card.Id);

            if (cardIndex !== -1) {
                if (newDeck.maindeck[cardIndex].amount > 1) {
                    newDeck.maindeck[cardIndex] = {
                        ...newDeck.maindeck[cardIndex],
                        amount: newDeck.maindeck[cardIndex].amount - 1
                    };
                } else {
                    newDeck.maindeck.splice(cardIndex, 1);
                }
                cardRemoved = true;
            }
        }

        if (cardRemoved && newDeck.id !== "") {
            SaveDeck(newDeck);
        }
    };

    function RemoveBench(card) {
        if (cardsOnly || !session) return false;

        setActiveDeck(prev => {
            if (!prev) return prev;
            
            const newDeck = { ...prev };
            newDeck.bench = [...(prev.bench || [])];
            
            const cardIndex = newDeck.bench.findIndex(c => c.Id === card.Id);
            
            if (cardIndex !== -1) {
                if (newDeck.bench[cardIndex].amount > 1) {
                    newDeck.bench[cardIndex] = {
                        ...newDeck.bench[cardIndex],
                        amount: newDeck.bench[cardIndex].amount - 1
                    };
                } else {
                    newDeck.bench.splice(cardIndex, 1);
                }

                if (newDeck.id !== "") {
                    SaveDeck(newDeck);
                }
            }

            return newDeck;
        });

        return true;
    };

    function ZeroCard(card) {
        if (cardsOnly) return;
        if (!session) return;
        if (card.type === "resources") {
            let fnd = false;
            for (let i = 0; i < activeDeck.resourcesdeck.length; i++) {
                let crd = activeDeck.resourcesdeck[i];
                if (crd.Id === card.Id) {
                    if (crd.amount > 1) {
                        crd.amount--;
                        fnd = true;
                        return true;
                    } else if (crd.amount === 1) {
                        crd.amount = 0;
                        fnd = true;
                        return true;
                    } else return false;
                }
            }
            if (!fnd) return false;
        } else {
            let fnd = false;
            for (let i = 0; i < activeDeck.maindeck.length; i++) {
                let crd = activeDeck.maindeck[i];
                if (crd.Id === card.Id) {
                    if (crd.amount > 1) {
                        crd.amount--;
                        fnd = true;
                        return true;
                    } else if (crd.amount === 1) {
                        crd.amount = 0;
                        fnd = true;
                        return true;
                    } else return false;
                }
            }
            if (!fnd) return false;
        }
    }

    function UnZeroCard(card) {
        if (cardsOnly) return;
        if (!session) return;
        if (card.type === "resources") {
            let fnd = false;
            for (let i = 0; i < activeDeck.resourcesdeck.length; i++) {
                let crd = activeDeck.resourcesdeck[i];
                if (crd.Id === card.Id) {
                    if (crd.amount === 0) {
                        crd.amount = 1;
                        fnd = true;
                        return true;
                    } else if (crd.amount >= 1) {
                        fnd = true;
                        return true;
                    } else return false;
                }
            }
            if (!fnd) return false;
        } else {
            let fnd = false;
            for (let i = 0; i < activeDeck.maindeck.length; i++) {
                let crd = activeDeck.maindeck[i];
                if (crd.Id === card.Id) {
                    if (crd.amount === 0) {
                        crd.amount = 1;
                        fnd = true;
                        return true;
                    } else if (crd.amount >= 1) {
                        fnd = true;
                        return true;
                    } else return false;
                }
            }
            if (!fnd) return false;
        }
    }

    function upvote(deck) {
        if (cardsOnly) return;
        if (!session) return;
        let nd = deck;
        Axios({
            method: "POST",
            url: dblink + "/api/decks/upvote",
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json"
            },
            data: JSON.stringify({"deck": nd, "voterid": id})
        }).then((res) => {
            if (res.data.status === "success") {
                getdecks();
                toast.success("Successfully upvoted the deck " + deck.deckName, {position: "bottom-right"});
            } else {
                toast.error("Failed to upvote the deck " + deck.deckName, {position: "bottom-right"});
            }
        });
    }

    function downvote(deck) {
        if (cardsOnly) return;
        if (!session) return;
        let nd = deck;
        Axios({
            method: "POST",
            url: dblink + "/api/decks/downvote",
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json"
            },
            data: JSON.stringify({"deck": nd, "voterid": id})
        }).then((res) => {
            if (res.data.status === "success") {
                getdecks();
                toast.success("Successfully downvoted the deck " + deck.deckName, {position: "bottom-right"});
            } else {
                toast.error("Failed to downvoted the deck " + deck.deckName, {position: "bottom-right"});
            }
        });
    }

    //#endregion

    //#region Helpers
    function totalAmountResources() {
        let tot = 0;
        if (cardsOnly) return 0;
        if (!activeDeck) return tot;
        else {
            for (let i = 0; i < activeDeck.resourcesdeck.length; i++) {
                let crd = activeDeck.resourcesdeck[i];
                tot += crd.amount;
            }
            return tot;
        }
    }

    function totalAmountMain() {
        if (cardsOnly) return 0;
        let tot = 0;
        if (!activeDeck) return tot;
        else {
            for (let i = 0; i < activeDeck.maindeck.length; i++) {
                let crd = activeDeck.maindeck[i];
                tot += crd.amount;
            }
            return tot;
        }
    }

    function GetCardData(str){
        let found = false;
        for(let i = 0; i < units.length; i++) {
            if(units[i]._id === str) {
                found = true;
                return units[i];
            }
        }
        for(let i = 0; i < actions.length; i++) {
            if(actions[i]._id === str) {
                found = true;
                return actions[i];
            }
        }
        for(let i = 0; i < resources.length; i++) {
            if(resources[i]._id === str) {
                found = true;
                return resources[i];
            }
        }
        if(!found) return null;
    }

    function calcRating(deck) {
        let rating = 0;
        if (deck.upvotes && deck.upvotes.length > 0) rating += deck.upvotes.length;
        if (deck.downvotes && deck.downvotes.length > 0) rating -= deck.downvotes.length;
        return rating;
    }

    function Preview(el) {
        setPreviewDeck(el);
        setPreviewing(true);
    }

    function isSameName(deck) {
        let same = false;
        myDecks.forEach((el) => {
            if (el.deckName === deck.deckName) same = true;
        });
        return same;
    }

    //#endregion

    //#region Filters & sorting
    const IsInBench = (id) => {
        if(!activeDeck) return false;
        let fnd = false;
        for (let i = 0; i < activeDeck.bench.length; i++) {
            let crd = activeDeck.bench[i];
            if (crd.Id === id) {
                fnd = true;
                break;
            }
        }
        return fnd;
    };

    const IsInDeck = (Id, resource) => {
        if (cardsOnly) return 0;
        let am = 0;

        if (!activeDeck) return 0;
        else {
            if (resource) {
                for (let i = 0; i < activeDeck.resourcesdeck.length; i++) {
                    if (activeDeck.resourcesdeck[i].Id === Id) {
                        am += activeDeck.resourcesdeck[i].amount
                        break;
                    }
                }
            } else {
                for (let i = 0; i < activeDeck.maindeck.length; i++) {
                    if (activeDeck.maindeck[i].Id === Id) {
                        am += activeDeck.maindeck[i].amount;
                        break;
                    }
                }
            }
            return am;
        }
    }

    async function BuildCards(){
        let cards = [];
        for (let i1 = 0; i1 < units.length; i1++){
            const el = units[i1];
            let totalcost = 0;
            for(let i= 0; i< el.costs.length; i++){
                totalcost += el.costs[i].amount;
            }
            let uic = {};
            uic.type = "units";
            uic.Id = el._id;
            uic.atk = el.atk;
            uic.def = el.def;
            uic.Name = el.name;
            uic.identity = el.identity;
            uic.abilitytext = el.abilitydescription;
            uic.keywords = el.keywords;
            uic.totalcost = totalcost;
            uic.inFirstEdition = el.inFirstEdition;
            cards.push(uic);
        }
        for (let i1 = 0; i1 < actions.length; i1++){
            const el = actions[i1];
            let uic = {};
            let totalcost = 0;
            for(let i= 0; i< el.costs.length; i++){
                totalcost += el.costs[i].amount;
            }
            uic.type = "actions";
            uic.Id = el._id;
            uic.Name = el.name;
            uic.identity = el.identity;
            uic.abilitytext = el.abilitydescription;
            uic.atk = 0;
            uic.def = 0;
            uic.keywords = [];
            if(el.triggers){
                for(let i = 0; i < el.triggers.length; i++) {
                    if(el.triggers[i].effect){
                        for(let i2 = 0; i2 < el.triggers[i].effect.length; i2++) {
                            if(el.triggers[i].effect[i2].type === "GainKeyword"){
                                let ks = el.triggers[i].effect[i2].valueC;
                                ks.forEach(el2 => {
                                    uic.keywords.push(el2);
                                });
                            }
                        }
                    }
                }
            }
            uic.totalcost = totalcost;
            uic.inFirstEdition = el.inFirstEdition;
            cards.push(uic);
        }
        for (let i = 0; i < resources.length; i++){
            const el = resources[i];
            let uic = {};
            uic.type = "resources";
            uic.Id = el._id;
            uic.Name = el.Name;
            uic.identity = el.identity;
            uic.abilitytext = "";
            uic.keywords = [];
            uic.atk = 0;
            uic.def = 0;
            uic.inFirstEdition = true;
            cards.push(uic);
        }
        setAllCards(cards);
    }

    function DisplayCards() {
        let cards = [];
        for (let i1 = 0; i1 < allCards.length; i1++){
            const el = allCards[i1];
            if (CheckFilters(el)) cards.push(el);
        }
        switch (sortType) {
            case "NameAsc":
                cards = cards.sort((a, b) => a.Name.toLowerCase() < b.Name.toLowerCase() ? -1 : 1);
                break;
            case "NameDesc":
                cards = cards.sort((a, b) => a.Name.toLowerCase() < b.Name.toLowerCase() ? -1 : 1);
                cards.reverse();
                break;
            case "CostAsc":
                cards = cards.sort((a, b) => a.totalcost < b.totalcost ? -1 : 1);
                break;
            case "CostDesc":
                cards = cards.sort((a, b) => a.totalcost < b.totalcost ? -1 : 1);
                cards.reverse();
                break;
            case "AttackAsc":
                cards = cards.sort((a, b) => a.atk < b.atk ? -1 : 1);
                break;
            case "AttackDesc":
                cards = cards.sort((a, b) => a.atk < b.atk ? -1 : 1);
                cards.reverse();
                break;
            case "DefenseAsc":
                cards = cards.sort((a, b) => a.def < b.def ? -1 : 1);
                break;
            case "DefenseDesc":
                cards = cards.sort((a, b) => a.def < b.def ? -1 : 1);
                cards.reverse();
                break;
            case "IdentityAsc":
                cards = cards.sort((a, b) => a.identity.toLowerCase() < b.identity.toLowerCase() ? -1 : 1);
                break;
            case "IdentityDesc":
                cards = cards.sort((a, b) => a.identity.toLowerCase() < b.identity.toLowerCase() ? -1 : 1);
                cards.reverse();
                break;
            default:
                cards = cards.sort((a, b) => a.Name.toLowerCase() < b.Name.toLowerCase() ? -1 : 1);
                break;
        }
        if(totalVisibleCards !== cards.length) setTotalVisibleCards(cards.length);
        return cards;
    }

    function CheckFilters(card) {
        let show = true;
        let srch = searchText.toLowerCase();
        if (DisplayMode === "Units" && card.type !== "units") return false;
        if (DisplayMode === "Actions" && card.type !== "actions")  return false;
        if (DisplayMode === "Mana" && card.type !== "resources")  return false;

        if (!cardsOnly && session) {
            if (deckfilterActive) {
                if (activeDeck2) {
                    if (card.type === "resources") {
                        for (let i = 0; i < activeDeck2.resourcesdeck.length; i++) {
                            if (activeDeck2.resourcesdeck[i].Id === card.Id) {
                                show = false;
                                break;
                            }
                        }
                    } else {
                        if (activeDeck2.maindeck) {
                            for (let i = 0; i < activeDeck2.maindeck.length; i++) {
                                if (activeDeck2.maindeck[i].Id === card.Id) {
                                    show = false;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
            if (activeDeck) {
                switch(InDeck){
                    case "deck":
                        if (IsInDeck(card.Id, card.type === "resources") < 1) show = false;
                        break;
                    case "notindeck":
                        if (!IsInDeck(card.Id, card.type === "resources") < 1) show = false;
                        break;
                    default:
                        show = true;
                }
            }
        }

        if (!show) return false;
        else {
            if (srch !== "") {
                if (card.Name.toLowerCase().includes(srch)) show = true;
                else {
                    let fixedtext = card.abilitytext.toLowerCase().replace("[", "");
                    fixedtext = fixedtext.replace("]", "");
                    fixedtext = fixedtext.replace("[i]", "");
                    fixedtext = fixedtext.replace("[/i]", "");
                    fixedtext = fixedtext.replace("[p]", "");
                    fixedtext = fixedtext.replace("[/p]", "");
                    show = fixedtext.includes(srch);
                }
            }

            if(show) {

                if (IdentityFilter.length > 0) {

                    if(identityNot){
                        if(identity === "Or"){
                            let shw = true;
                            for(let i = 0; i < IdentityFilter.length; i++) {
                                if (card.identity.includes(IdentityFilter[i])) {
                                    shw = false;
                                    break;
                                }
                            }
                            show = shw;
                        } else {
                            let fnd = 0;
                            for(let i = 0; i < IdentityFilter.length; i++) {
                                if (card.identity.includes(IdentityFilter[i])) {
                                    fnd++;
                                }
                            }
                            show = fnd < IdentityFilter.length;
                        }
                    } else {
                        if(identity === "Or"){
                            let shw = false;
                            for(let i = 0; i < IdentityFilter.length; i++) {
                                if (card.identity.includes(IdentityFilter[i])) {
                                    shw = true;
                                    break;
                                }
                            }
                            show = shw;
                        } else {
                            let fnd = 0;
                            for(let i = 0; i < IdentityFilter.length; i++) {
                                if (card.identity.includes(IdentityFilter[i])) {
                                    fnd++;
                                }
                            }
                            show = fnd >= IdentityFilter.length;
                        }
                    }
                }

                if (show && keywordFilter.length > 0) {
                    if(keywordNot){
                        if(keyword === "Or"){
                            let shw = true;
                            for(let i = 0; i < keywordFilter.length; i++) {
                                if (card.keywords.includes(keywordFilter[i])) {
                                    shw = false;
                                    break;
                                }
                            }
                            show = shw;
                        } else {
                            let fnd = 0;
                            for(let i = 0; i < keywordFilter.length; i++) {
                                if (card.keywords.includes(keywordFilter[i])) {
                                    fnd++;
                                }
                            }
                            show = fnd < keywordFilter.length;
                        }
                    } else {
                        if(keyword === "Or"){
                            let shw = false;
                            for(let i = 0; i < keywordFilter.length; i++) {
                                if (card.keywords.includes(keywordFilter[i])) {
                                    shw = true;
                                    break;
                                }
                            }
                            show = shw;
                        } else {
                            let fnd = 0;
                            for(let i = 0; i < keywordFilter.length; i++) {
                                if (card.keywords.includes(keywordFilter[i])) {
                                    fnd++;
                                }
                            }
                            show = fnd >= keywordFilter.length;
                        }
                    }
                }
            }
            return show;
        }
    }

    function MultiCostIcons(ele, amount){
        let ls = [];
        for(let i = 0; i < amount; i++){
            ls.push(ele);
        }
        return(
        <>
            {ls.map((el, ind) => (
                <img key={ind} className={"h-6 w-auto"} src={"/images/" + el + ".png"}
                     alt={el}/>
            ))}
        </>)
    }

    function ManaCardCount(){
        let am = 0;
        if(activeDeck === null || activeDeck.resourcesdeck === null) return (<p className={"flex justify-center items-center h-full w-8 "}>0</p>);
        else {
            for(let c = 0; c < activeDeck.resourcesdeck.length; c++) {
                let card = GetCardData(activeDeck.resourcesdeck[c].Id);
                if(card){
                    am += activeDeck.resourcesdeck[c].amount;
                }
            }
            return  (<p className={"flex justify-center items-center h-full w-auto"}>{am}</p>)
        }
    }

    function ManaIcons(props){
        const {ele} = props;
        let ls = [];
        if(ele.toString().includes(" ")) ls = ele.split(" ");
        else ls.push(ele);
        return(
            <>
                {ls.map((el,ind) => (
                    <img key={ind} className={"h-6 w-auto"} src={"/images/" + el + ".png"}
                         alt={el}/>
                ))}
            </>)
    }

    function ElementCosts(props) {
        const {element, amount} = props;
        let ele = element.toLowerCase();
        if(ele === "nonspecific"){
            ele = "nonspecific" + amount;
        }
        return (
            <div className={"flex flex-row h-full text-sm w-auto justify-center items-center"}>
                {element.toLowerCase() === "nonspecific" ? <img className={"h-6 w-auto"} src={"/images/" + ele + ".png"}
                                                                alt={element}/> : MultiCostIcons(ele,amount)}
            </div>
        )
    }

    function ResourceAmount(props) {
        const {str} = props;
        let am = 0;
        if(activeDeck === null || activeDeck.resourcesdeck === null) return (<div className={"flex justify-center items-center h-full w-auto "}>0</div>);
        else {
            for(let c = 0; c < activeDeck.resourcesdeck.length; c++) {
                let card = GetCardData(activeDeck.resourcesdeck[c].Id);
                if(card){
                    if(card.identity.toLowerCase().includes(str.toLowerCase())){
                        am += activeDeck.resourcesdeck[c].amount;
                    }
                }
            }
            return  (<div className={"flex justify-center items-center h-full w-auto"}>{am}</div>)
        }
    }

    function TotalCosts(props){
        const {str} = props;
        let am = 0;
        if(activeDeck === null || activeDeck.maindeck === null) return (<p className={"flex justify-center items-center h-full w-auto "}>0</p>);
        else {
            for(let c = 0; c < activeDeck.maindeck.length; c++){
                let card = GetCardData(activeDeck.maindeck[c].Id)
                if(card) {
                    if (card.costs && card.costs.length > 0) {
                        for (let i = 0; i < card.costs.length; i++) {
                            if (card.costs[i].element.toLowerCase().includes(str) || str === "all") {
                                am += card.costs[i].amount * activeDeck.maindeck[c].amount;
                            }
                        }
                    }
                }
            }
            return (<p className={"flex justify-center items-center h-full w-auto "}>{am}</p>)
        }
    }

    //#endregion

    //#region Public Decks
    useEffect(() => {
        if(actions && units && resources) BuildCards();
    }, [actions, units, resources]);

    useEffect(() => {
        if(decks && decks.length > 0){
            BuildDeckPages();
        }
    }, [decks, deckst, showOwn, hideAdded, hideIncomplete, myDecks,decksPerPage, currentDeckPage]);

    function BuildDeckPages(){
        let dp = [];
        for(let i=0; i< decks.length; i++){
            let vis = false;
            let dk = decks[i];
            if(deckst !== ""){
                let fnd = false;
                if(dk.description.includes(deckst)) fnd = true;
                if(dk.deckName.includes(deckst)) fnd = true;
                if(dk.ownerName.includes(deckst)) fnd = true;
                vis = fnd;
            } else vis = true;

            if(vis) {
                if(hideAdded && HasDeck(dk.id)) vis = false;
                if(!showOwn && OwnDeck(dk.id)) vis = false;
                if(hideIncomplete){
                    if(dk.maindeck.length < 50) vis = false;
                    if(dk.resourcesdeck.length > 0) {
                        let total = 0;
                        for(let i = 0; i < dk.resourcesdeck.length; i++){
                            total += dk.resourcesdeck[i].amount;
                        }
                        if(total < maxmana -1) vis = false;
                    }
                }
            }

            if(vis) dp.push(dk);
        }
        let pages = [];
        for (let i = 0; i < dp.length; i += decksPerPage) {
            let page = {};
            page.decks = dp.slice(i, i + decksPerPage);
            pages.push(page);
        }
        setDeckPages(pages);
    }
    //#endregion

    return (
        <DbContext.Provider
            value={{
                activeDeck,
                activeDeck2,
                setActiveDeck,
                setActiveDeck2,
                units,
                actions,
                resources,
                cardSize,
                setCardSize,
                cardsPerPage,
                setCardsPerPage,
                myDecks,
                decks,
                keywords,
                elements,
                identities,
                previewDeck,
                previewing,
                setPreviewDeck,
                setPreviewing,
                calcRating,
                aD2,
                swapDisplayMode,
                DisplayMode,
                keywordFilter,
                setKeywordFilter,
                searchText,
                setSearchText,
                OwnDeck,
                currentPage,
                setCurrentPage,
                currentPageLabel: currentPage +1,
                DisplayCards,
                starterScreen,
                setStarterScreen,
                IdentityFilter,
                setIdentityFilter,
                sortType,
                setSortType,
                addDeck,
                Preview,
                AddCard,
                SaveDeck,
                DeleteDeck,
                RemoveCard,
                upvote,
                downvote,
                cardbacks,
                deckfilterActive,
                setDeckfilterActive,
                edition,
                setEdition,
                IsInDeck,
                InDeck,
                SetInDeck,
                totalAmountMain,
                totalAmountResources,
                GetCardData,
                ElementCosts,
                TotalCosts,
                editions,
                ResourceAmount,
                ManaIcons,
                ManaCardCount,
                maxmain,
                maxmana,
                ZeroCard,
                UnZeroCard,
                TotalCards,
                getdecks,
                deckWindow,
                setDeckWindow,
                RemoveBench,
                AddBench,
                IsInBench,
                decksLoading,
                SetResourceAmount,
                HasDeck,
                getcards,
                showOwn,
                setShowOwn,
                hideAdded,
                setHideAdded,
                hideIncomplete,
                setHideIncomplete,
                identity,
                setIdentity,
                deckst,
                setDeckSt,
                decksPerPage,
                setDecksPerPage ,
                currentDeckPage,
                setCurrentDeckPage,
                currentDeckPageLabel,
                setCurrentDeckPageLabel,
                deckPages,
                setDeckPages,
                cardTooltips,
                setCardTooltips,
                identityNot,
                setIdentityNot,
                keywordNot,
                setKeywordNot,
                keyword,
                setKeyword,
            }}>
            {children}
        </DbContext.Provider>)
}

const useDbContext = () => useContext(DbContext);

export { DbContext as default, DbContextProvider, useDbContext };