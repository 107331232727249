import React, {Suspense, useEffect, useState} from "react";
import {useDownload} from "./useDownload";
import { json2csv } from 'json-2-csv';
import toast from "react-hot-toast";
import {supabase} from "../utils/supabase";
import {useAtom} from "jotai/index";
import {decksAtom, loadingDecksAtom} from "../atoms/adminAtoms";
import {Button} from "@mantine/core";

export default function DecksList(props) {
    const [decks, setDecks] = useAtom(decksAtom);
    const [loadingDecks, setLoadingDecks] = useAtom(loadingDecksAtom);
    const [sortType, setSortType] = useState("id");

    useEffect(() => {
        LoadDecks();
    }, []);

    function displaydeckss(){
        switch(sortType) {
            case "id":
                return decks.sort((a, b) => a.id < b.id ? -1 : 1);
            case "origin":
                return decks.sort((a, b) => a.origin < b.origin ? -1 : 1);
            case "name":
                return decks.sort((a, b) => a.deckName < b.deckName ? -1 : 1);
            case "owner":
                return decks.sort((a, b) => a.ownerName < b.ownerName ? -1 : 1);
            case "description":
                return decks.sort((a, b) => a.description < b.description ? -1 : 1);
            case "maindeck":
                return decks.sort((a, b) => a.maindeck < b.maindeck.length ? -1 : 1);
            case "mana":
                return decks.sort((a, b) => a.resourcesdeck.length < b.resourcesdeck.length ? -1 : 1);
            default:
                return decks.sort((a, b) => a.id < b.id ? -1 : 1);
        }
    }

    const LoadDecks = async () => {
        setLoadingDecks(true);
        const {data} = await supabase
            .from('decks')
            .select();
        if (data && data.length > 0) {
            setDecks(data);
        } else {
            setDecks([]);
        }
        setLoadingDecks(false);
    };

    return (
        <div className={"flex flex-col w-full h-full p-10 overflow-hidden"}>
            <Suspense fallback={<div>Loading decks data...</div>}>
                <div className={"flex flex-row justify-center items- center h-10 w-full overflow-hidden"}>
                    <Button variant={"default"} onClick={() => {
                        const fileData = JSON.stringify(decks);
                        const blob = new Blob([fileData], { type: "text/json" });
                        const url = URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.download = "decks.json";
                        link.href = url;
                        link.click();
                    }}>Download all Decks</Button>
                </div>
            <div className="flex overflow-y-auto h-full w-full border border-[#51b78c] border-opacity-40">
                <table className="table w-full table-xs h-auto table-pin-rows table-pin-cols justify-center items-center">
                    <DecksHeader sortType={sortType} setSortType={setSortType} />
                <tbody>
                {decks && displaydeckss().map((el, ind) =>
                    <DeckItem key={ind} entry={el}/>)}
                </tbody>
            </table>
            </div>
            </Suspense>
        </div>
    )
}

function DecksHeader(props) {
    const {sortType, setSortType} = props;
    return (
        <thead>
        <tr>
            <th className={sortType === "id" ? "text-green-400" : "text-white cursor-pointer"} onClick={() => setSortType("id")}>ID</th>
            <th className={sortType === "origin" ? "text-green-400" : "text-white cursor-pointer"} onClick={() => setSortType("origin")}>Origin</th>
            <th className={sortType === "name" ? "text-green-400" : "text-white cursor-pointer"} onClick={() => setSortType("name")}>DeckName</th>
            <th className={sortType === "owner" ? "text-green-400" : "text-white cursor-pointer"} onClick={() => setSortType("owner")}>Owner</th>
            <th className={sortType === "description" ? "text-green-400" : "text-white cursor-pointer"}  onClick={() => setSortType("description")}>Description</th>
            <th className={sortType === "maindeck" ? "text-green-400" : "text-white cursor-pointer"}  onClick={() => setSortType("maindeck")}>MainDeck</th>
            <th className={sortType === "mana" ? "text-green-400" : "text-white cursor-pointer"}  onClick={() => setSortType("mana")}>Mana</th>
            <th>Export</th>
        </tr>
        </thead>
    )
}

const playerentrystyle = "flex-row text-center justify-center items-center border-r border-[#51b78c] border-opacity-40";

function DeckItem(props) {
    const {entry} = props;
    const { handleZip } = useDownload();
    const [cards, setCards] = useState([]);
    useEffect(() => {
        if(entry) {
            GetCardList();
        }
    }, [entry]);

    function GetCardList(){
        let co = {};
        co.names = [];
        co.cards = [];
        let ls = [];
        let ls2 = [];
        for(let i = 0; i < entry.maindeck.length; i++) {
            let nc = "cards/" + entry.maindeck[i].Id + ".png";
            ls.push(nc);
            ls2.push(entry.maindeck[i].Name);
        }
        for(let i = 0; i < entry.resourcesdeck.length; i++) {
            let nc = "cards/" + entry.resourcesdeck[i].Id + ".png";
            for(let i2 = 0; i2 < entry.resourcesdeck[i].amount; i2++){
                ls.push(nc);
                ls2.push(entry.resourcesdeck[i].Name);
            }
        }
        for(let i = 0; i < entry.bench.length; i++) {
            let nc = "cards/" + entry.bench[i].Id + ".png";
            ls.push(nc);
            ls2.push(entry.bench[i].Name);
        }
        co.cards = ls;
        co.names = ls2;
        setCards(co);
    }

    function PurgedList(clist){
        let cl = [];
        for(let i = 0; i < clist.length; i++){
            cl.push(clist[i].Name);
        }
        return cl;
    }

    function ManaCount(rdeck){
        let am = 0;
        for(let i = 0; i < rdeck.length; i++){
            am += rdeck[i].amount;
        }
        return am;
    }

    return (
        <tr className={"border border-[#51b78c] border-opacity-40"}>
            <td className={playerentrystyle}>{entry.id}</td>
            <th className={playerentrystyle}>{entry.origin && entry.origin === -1 ? "New" : "Deck " + entry.origin}</th>
            <th className={playerentrystyle}>{entry.deckName}</th>
            <td className={playerentrystyle}>{entry.ownerName}</td>
            <td className={playerentrystyle}>{entry.description}</td>
            <td className={playerentrystyle}>{entry.maindeck.length}</td>
            <td className={playerentrystyle}>{ManaCount(entry.resourcesdeck)}</td>
            <td className={playerentrystyle}>
                <>
                    <Button onClick={() => {
                        handleZip(cards.cards, cards.names);
                    }} className={"btn-ghost"}>Images</Button>
                    <Button onClick={() => {
                        let nc = entry;
                        nc.maindeck = PurgedList(entry.maindeck);
                        nc.resourcesdeck = PurgedList(entry.resourcesdeck);
                        nc.bench = PurgedList(entry.bench);
                        navigator.clipboard.writeText(JSON.stringify(nc));
                        toast.success('Deck exported to clipboard in JSON format.', {
                            duration: 4000,
                            position: 'bottom-right',
                        });
                    }
                    }>
                        JSON
                    </Button>
                    <Button onClick={() => {
                        let nc = entry;
                        nc.maindeck = PurgedList(entry.maindeck);
                        nc.resourcesdeck = PurgedList(entry.resourcesdeck);
                        nc.bench = PurgedList(entry.bench);
                        const csv = json2csv(nc);
                        navigator.clipboard.writeText(csv);
                        toast.success('Deck exported to clipboard in CSV format.', {
                            duration: 4000,
                            position: 'bottom-right',
                        });
                    }
                    }>
                        CSV
                    </Button>
                </>
            </td>
        </tr>
    )
}