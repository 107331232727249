import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;

export function gethDateTime() {
    const months = [
        "01", "02", "03", "04", "05", "06",
        "07", "08", "09", "10", "11", "12"
    ];
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = months[currentDate.getMonth()];
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');

    const dateTimeString = `${day}/${month}/${year} at ${hours}:${minutes}:${seconds}`;
    return dateTimeString;
}

export function getISOtime() {
    return new Date().toISOString().slice(0, 19).replace('T', ' ');
}

export const supabase = createClient(supabaseUrl, supabaseKey, {});

const opts = { auth: {
    storageKey: 'sb_access_token',
        storage: {
        getItem: (key) => {
            const item = localStorage.getItem(key);
            if(item){
                const oneYear = new Date();
                oneYear.setFullYear(oneYear.getFullYear() + 1);
                document.cookie = `${key}=${item}; path=/; domain=.manacaster.com; expires=${oneYear.toUTCString()}; secure; samesite=strict; partitioned`
            }
            return item;
        },
            setItem: (key, value) => {
            localStorage.setItem(key, value);
            const oneYear = new Date();
            oneYear.setFullYear(oneYear.getFullYear() + 1);
            document.cookie = `${key}=${value}; path=/; domain=.manacaster.com; expires=${oneYear.toUTCString()}; secure; samesite=strict; partitioned`
        },
            removeItem: (key) => {
            localStorage.removeItem(key);
            document.cookie = `${key}=; path=/; domain=.manacaster.com; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=strict; partitioned`
        }
    }
}}