import React, {Suspense, useEffect, useState} from "react";
import {bordercolor} from "../theme";
import MatchLogEntry from "../statistics/matchlogentry";
import ActiveLogEntry from "../statistics/activeLogEntry";
import MatchLogList from "../statistics/matchlogList";
import {useAppContext} from "../contexts/appContext";
import {Pagination, ScrollArea} from "@mantine/core";
import {
    activeReportAtom,
    activeSortAtom,
    activeTypeAtom,
    loadingRMAtom,
    reportsAtom,
    sortedReportsAtom
} from "../atoms/adminAtoms";
import {useAtom, useAtomValue} from "jotai/index";
import {supabase} from "../utils/supabase";
import toast from "react-hot-toast";

export function MatchOverview(props) {
    const {cardsdate} = useAppContext();
    const [hoverId, setHoverID] = useState("");
    const [activeType, setActiveType] = useAtom(activeTypeAtom);
    const [activeReport, setActiveReport] = useAtom(activeReportAtom);
    const [activeSort, setActiveSort] = useAtom(activeSortAtom);
    const [reports, setReports] = useAtom(reportsAtom);
    const sortedReports = useAtomValue(sortedReportsAtom);
    const [loaded, setLoaded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // You can adjust this number based on your preference
    const paginatedReports = reports ? reports.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    ) : [];
    const totalPages = Math.ceil((reports?.length || 0) / itemsPerPage);


    useEffect(() => {
        if(!loaded) {
            GetReports().then(() => setLoaded(true));
        }
    }, [loaded])

    async function GetReports(){
        const {data, error} = await supabase
            .from('matchreports')
            .select('*')
            .order('created_at', { ascending: false });
        setReports(data || []);
    }

    async function DeleteReport(mid) {
        await supabase
            .from('matchreports')
            .delete()
            .eq('id', mid).then(result => {
                if (result.error) {
                    toast.error("Failed to delete the match report.", {position: "bottom-right"});
                } else {
                    toast.success("Successfully deleted the match report.", {position: "bottom-right"});
                    GetReports();
                    setActiveReport(null);
                }
            })
    }

    return (
        <div className={"flex flex-col w-full h-full bg-[#131a20] overflow-hidden bg-opacity-40 justify-center items-center"}>
            <Suspense fallback={<div>Loading statistics data...</div>}>
            <div className={"flex flex-row text-sm h-full w-full overflow-hidden"}>
                <MatchLogList GetReports={GetReports} admin={true} activeType={activeType} setActiveType={setActiveType} activeReport={activeReport} activeSort={activeSort} setActiveSort={setActiveSort} setActiveReport={setActiveReport} reports={reports} SortedReports={sortedReports} />
                <div className="flex flex-col h-full w-[80%] overflow-hidden">
                    <div className="flex flex-col w-full h-full mx-auto overflow-hidden">
                        {!activeReport &&
                                <div className={"flex flex-col w-full h-full overflow-hidden"}>
                                    <div className={"flex flex-col w-full h-auto px-4 pb-8 pt-2 overflow-y-auto"}>
                                        <div className={"flex flex-col w-full h-auto py-8"}>
                                        {paginatedReports.map((el, ind) =>
                                            <MatchLogEntry
                                                user={false}
                                                DeleteReport={DeleteReport}
                                                activeType={activeType}
                                                activeReport={activeReport}
                                                key={ind}
                                                ind={ind}
                                                report={el}
                                            />
                                        )}
                                        </div>
                                    </div>
                                    <div className="flex justify-center h-10 mt-4 mb-2">
                                        <Pagination
                                            total={totalPages}
                                            value={currentPage}
                                            onChange={setCurrentPage}
                                            color="teal"
                                            size="md"
                                            withEdges
                                        />
                                    </div>
                                </div>}
                        {activeReport && <ActiveLogEntry user={false} DeleteReport={DeleteReport} activeReport={activeReport}  setHoverID={setHoverID} hoverId={hoverId}/>}
                    </div>
                </div>
            </div>
            </Suspense>
            {hoverId !== "" &&
                <div
                    className={"absolute pointer-events-none flex flex-col min-h-40 h-auto w-[400px] left-4 top-[20%] p-3 overflow-hidden self-center justify-self-center bg-[#0a0f13] rounded-2xl border " + bordercolor}>
                    <img className={"h-auto w-full rounded-2xl"} alt={"preview" + hoverId}
                         src={"/cards/" + hoverId + ".png?key=" + cardsdate}/>
                </div>}
        </div>
    )
}