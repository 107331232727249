import React, {useEffect, useState} from "react";
import Tooltip from "@mui/material/Tooltip";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {MultiSelect, ScrollArea, Switch, Badge, NumberInput} from "@mantine/core";

const deploystyle = "flex flex-row h-10 w-full font-semibold justify-center text-lg uppercase items-center border-b border-[#51b78c] border-opacity-50"
const deployedstyle = "flex cursor-pointer flex-row h-8 w-full italic justify-center items-center bg-[#3b6f5e26] hover:bg-opacity-40 border-b border-[#51b78c] border-opacity-50"

const cols = " bg-red-600 text-black bg-blue-500 bg-amber-500 bg-cyan-500 bg-slate-900 bg-slate-700 bg-fuchsia-700 bg-rose-700 bg-purple-700 bg-gray-700 bg-cyan-800 "

export function LogColor(ltype){
    switch(ltype){
        case "UnitDamage":
            return " bg-red-600 text-black ";
        case "ActionDamage":
            return " bg-red-500 text-black ";
        case "Drafting":
            return " bg-blue-600 text-white ";
        case "Trigger":
            return " bg-blue-500 text-white ";
        case "Keyword":
            return " bg-amber-500 text-black ";
        case "Player":
            return " bg-slate-800 text-white ";
        case "Status":
            return " bg-cyan-800 text-white ";
        case "Deploy":
            return " bg-cyan-700 text-white ";
        case "CardTap":
            return " bg-fuchsia-700 text-white ";
        case "Selections":
            return " bg-purple-700 text-white ";
        case "Reset":
            return " bg-gray-700 text-white ";
        case "Discard":
            return " bg-rose-700 text-black ";
    }
}

export default function ActiveLogEntry(props) {
    const {setHoverID, hoverId, activeReport, DeleteReport, user} = props;
    const [openTab, setOpenTab] = useState("log");
    const [detailsOpen, setDetailsOpen] = useState(false);

    function Deployed(str){
        if(activeReport.pOneID === str) return activeReport.PlayerOne;
        else return activeReport.PlayerTwo;
    }
    const [logTurnFilter, setLogTurnFilter] = useState(0);
    const [logFilter, setLogFilter] = useState([]);
    const [filteredLogs, setFilteredLogs] = useState([]);

    useEffect(() => {
        if(logFilter.length > 0){
            let lgs = [];
            for(let i = 0; i < activeReport.log.length; i++){
                if(logFilter.includes(activeReport.log[i].type)){
                    if(logTurnFilter > 0) {
                        if(activeReport.log[i].turn === logTurnFilter) lgs.push(activeReport.log[i]);
                    } else lgs.push(activeReport.log[i]);
                }
            }
            setFilteredLogs(lgs);
        } else {
            let lgs = [];
            if(logTurnFilter > 0) {
                for(let i = 0; i < activeReport.log.length; i++){
                    if(activeReport.log[i].turn === logTurnFilter) lgs.push(activeReport.log[i]);
                }
                setFilteredLogs(lgs);
            } else setFilteredLogs(activeReport.log);
        }
    }, [activeReport ,logFilter, logTurnFilter])

    return (
        <div className="p-4 flex flex-col h-full w-full gap-2 overflow-hidden">
            <div className={(detailsOpen ? "h-[300px] flex-col " : "h-14 flex-row justify-center items-center ") + "flex w-full bg-[#3b6f5e26] rounded-lg shadow-md shadow-black border border-[#51b78c] p-2"}>

                <div className={"flex flex-row h-12 w-full justify-beteen"}>

                    <div className="flex flex-row h-auto gap-10 w-full">
                        <div className="flex flex-row w-auto h-full justify-center items-center">
                            <Switch label="Details" checked={detailsOpen}
                                    onChange={(event) => setDetailsOpen(event.currentTarget.checked)}/>
                        </div>
                            <h2 className="flex flex-row w-auto h-auto justify-center items-center text-sm text-[#6de9d8] font-semibold">{"Match " + activeReport.MatchID}</h2>
                        <p className="flex flex-row w-full h-auto text-md justify-center items-center h-8 text-[#6de9d8] font-semibold gap-2">{activeReport.PlayerOne + " vs " +
                            activeReport.PlayerTwo}
                        <Badge color="blue">{activeReport.matchtype}</Badge></p>
                    </div>
                    {!user && detailsOpen && <DeleteWidget report={activeReport} DeleteReport={DeleteReport} activeReport={activeReport}/>}
                </div>

                {detailsOpen &&
                    <>
                        <div className={"h-2"}></div>
                        <div className="flex flex-row h-8 w-full text-[#6de9d8] text-sm justify-start items-start">{"Player 1: <" +
                            activeReport.pOneID + "> " + activeReport.PlayerOne}{activeReport.winnerID === activeReport.pOneID &&
                            <WinnerMedal/>}
                        </div>
                        <div className="flex flex-row h-8 w-full text-[#6de9d8] text-sm justify-start items-start">{"Player 1 Deck: <" +
                            activeReport.playerADeck.id + "> " + activeReport.playerADeck.name}
                        </div>
                        <div className={"h-2"}></div>
                        <div
                            className="flex flex-row h-8 w-full text-[#6de9d8] text-sm justify-start items-start">{"Player 2: <" +
                            activeReport.pTwoID + "> " + activeReport.PlayerTwo}{activeReport.winnerID === activeReport.pTwoID &&
                            <WinnerMedal/>}
                        </div>
                        <div
                            className="flex flex-row h-8 w-full text-[#6de9d8] text-sm justify-start items-start">{"Player 2 Deck: <" +
                            activeReport.playerBDeck.id + "> " + activeReport.playerBDeck.name}
                        </div>
                        <p className="flex flex-row h-8 w-full text-[#6de9d8] text-sm justify-start items-start">{"Winner: " + activeReport.winnerID}</p>

                        <div className={"h-2"}></div>
                        <p className="flex flex-row h-8 w-full text-[#6de9d8] text-sm justify-start items-start">{"Total Turns: " + activeReport.totalTurns}</p>
                        <p className="flex flex-row h-8 w-full text-[#6de9d8] text-sm justify-start items-end">{"Started on " + activeReport.startTime}</p>
                        <p className="flex flex-row h-8 w-full text-[#6de9d8] text-sm justify-start items-end">{"Ended on " + activeReport.endTime}</p>
                    </>
                }

                <div className="flex flex-row h-8 w-full text-[#6de9d8] text-sm justify-between items-end">
                    <p></p>
                    <div className={"flex flex-row h-full gap-2 w-auto justify-center items-center"}>
                        <div className={(openTab === "log" ? " flex " : " hidden ") + " flex-row h-auto w-auto gap-2"}>
                                <div className="flex flex-row h-auto w-auto justify-center items-center text-[#6de9d8]">Turn Filter</div>
                                <div className="flex flex-row h-auto w-[50px] text-[#6de9d8]">
                                    <NumberInput
                                        placeholder="0 for all turns"
                                        value={logTurnFilter}
                                        onChange={setLogTurnFilter}
                                        min={0}
                                        max={activeReport.totalTurns}
                                    />
                                </div>
                            <div className="flex flex-row h-10 w-auto justify-center items-center text-[#6de9d8]">
                                Log Filter
                            </div>
                            <div className="flex flex-row h-8 w-[200px] text-[#6de9d8]">
                                <MultiSelect w={"100%"} clearable
                                             data={["UnitDamage", "ActionDamage", "Trigger", "Keyword", "Player", "Status", "Deploy", "CardTap", "Selections", "Reset", "Discard", "Drafting"]}
                                             value={logFilter} onChange={setLogFilter}/>
                            </div>
                        </div>
                        <div
                            className={(openTab === "deploys" ? "bg-[#51b78c] text-black" : "text-[#6de9d8]") + " flex flex-row border border-[#51b78c] w-auto h-auto p-1 justify-center items-center"}
                            onClick={() => setOpenTab("deploys")}>Deployments
                        </div>
                        <div
                            className={(openTab === "log" ? "bg-[#51b78c] text-black" : "text-[#6de9d8]") + " flex flex-row w-auto h-auto border border-[#51b78c] p-1 justify-center items-center"}
                            onClick={() => setOpenTab("log")}>Logs
                        </div>
                    </div>
                </div>
            </div>

            <div className={"flex flex-col h-full w-full overflow-hidden bg-[#3b6f5e26] rounded-lg shadow-md shadow-black border border-[#51b78c] "}>
                <div className={"flex flex-col w-full overflow-hidden p-2 h-full "}>
                    {openTab === "log" &&
                        <div className={"flex flex-col gap-1 h-full w-full overflow-y-auto"}>
                            <ScrollArea h={"100%"}>
                            <div className={"flex flex-col gap-1 h-auto w-full"}>
                                {filteredLogs && filteredLogs.map((el, ind) =>
                                    <div key={ind}
                                         className={"flex flex-row w-full h-auto text-sm " + LogColor(el.type)}>
                                        {el.log}
                                    </div>)}
                            </div>
                            </ScrollArea>
                        </div>}
                    {openTab === "deploys" &&
                        <div className={"flex flex-row gap-1 h-full w-full overflow-hidden"}>
                            <div
                                className={"flex flex-col w-1/3 h-full overflow-y-auto rounded-xl border border-[#51b78c]"}>
                                <p className={deploystyle}>Unit Deploys</p>
                                {activeReport.unitDeployed && activeReport.unitDeployed.map((rep,ind) => (
                                    <div key={ind} onMouseLeave={() => setHoverID("")} onMouseEnter={() => setHoverID(rep.cardid)}
                                         className={deployedstyle}>
                                        {rep.cardName + " by " + Deployed(rep.deployer)}
                                    </div>
                                ))}
                            </div>
                            <div
                                className={"flex flex-col w-1/3 h-full overflow-y-auto rounded-xl border border-[#51b78c]"}>
                                <p className={deploystyle}>Action Deploys</p>
                                {activeReport.actionsDeployed && activeReport.actionsDeployed.map((rep, ind) => (
                                    <div key={ind} onMouseLeave={() => setHoverID("")} onMouseEnter={() => setHoverID(rep.cardid)}
                                         className={deployedstyle}>
                                        {rep.cardName + " by " + Deployed(rep.deployer)}
                                    </div>
                                ))}
                            </div>
                            <div
                                className={"flex flex-col w-1/3 h-full overflow-y-auto rounded-xl border border-[#51b78c]"}>
                                <p className={deploystyle}>Mana Deploys</p>
                                {activeReport.rcardsDeployed && activeReport.rcardsDeployed.map((rep, ind) => (
                                    <div key={ind} onMouseLeave={() => setHoverID("")} onMouseEnter={() => setHoverID(rep.cardid)}
                                         className={deployedstyle}>
                                        {rep.cardName + " by " + Deployed(rep.deployer)}
                                    </div>
                                ))}
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    )
}

export function WinnerMedal(){
    return (
        <Tooltip title="Winner" placement="left" >
            <p className={"bg-yellow-500 text-black ml-2 pl-2 pr-3 rounded-2xl text-sm uppercase"}><MilitaryTechIcon/>Winner</p>
        </Tooltip>
    )
}

export function DeleteWidget(props){
    const {report, DeleteReport, activeReport} = props;
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        setExpanded(false);
    }, [activeReport]);

    if(expanded) {
        return (
            <div className={"flex flex-row w-[550px] select-none border border-[#51b78c] h-14 gap-2 bg-slate-300 bg-opacity-10 p-2 text-xs rounded-3xl justify-center items-center"}>
                <p>This will delete this report, are you sure?</p>

                <Tooltip title="Confirm" placement="top" >
                    <div onClick={() => DeleteReport(report.id)}
                         className={"flex flex-row w-auto h-8 px-2 cursor-pointer bg-green-700 rounded-xl justify-center items-center"}>
                        <p>Yes, Delete</p>
                    </div>
                </Tooltip>
                <Tooltip title="Cancel" placement="top" >
                    <div onClick={() => {setExpanded(false);}}
                         className={"flex flex-row w-auto h-8 px-2 cursor-pointer bg-red-700 rounded-xl justify-center items-center"}>
                        Cancel
                    </div>
                </Tooltip>
            </div>
        )
    } else return (
        <Tooltip title="Delete match report" placement="top" >
            <div onClick={() => {setExpanded(true);}}
                 className={"flex flex-row w-12 h-12 cursor-pointer border border-[#51b78c] bg-slate-700 rounded-3xl justify-center items-center"}>
                <DeleteForeverIcon/>
            </div>
        </Tooltip>
    )
}